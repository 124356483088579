<div class="text-center mt-4"><om-logo class="logo mx-auto"></om-logo></div>

<om-flash></om-flash>

<ng-container *rxLet="documentSignerAndBeneficiary$; let documentSignerAndBeneficiary">
  <om-terms-of-service
    [beneficiaryLoggedIn]="!!documentSignerAndBeneficiary[0]"
    [signOnBehalf]="!!documentSignerAndBeneficiary[0]"
    [signer]="documentSignerAndBeneficiary[0]"
    [beneficiary]="documentSignerAndBeneficiary[1]"
    (nextAction)="continue()"
  >
  </om-terms-of-service>
</ng-container>
