import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { State } from '@app/app.reducer';
import { updateAnalyticsProperties } from '@app/core/analytics.actions';

import { StorageService } from './storage.service';

export interface TargetUser {
  id?: string;
  fhirIdentifier?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TargetUserService {
  private targetUser: TargetUser | null;

  constructor(private storageService: StorageService, protected store: Store<State>) {
    this.targetUser = null;
  }

  getTargetUser(): TargetUser | null {
    const targetUserIdentifier = this.storageService.getItem('target_user_id');
    if (targetUserIdentifier) {
      return {
        fhirIdentifier: targetUserIdentifier,
      };
    }
    return this.targetUser;
  }

  setTargetUser(target: TargetUser) {
    this.targetUser = target;
    this.store.dispatch(
      updateAnalyticsProperties({
        updates: {
          switched_account: true,
        },
      }),
    );
  }

  resetTargetUser() {
    this.targetUser = null;
    this.store.dispatch(
      updateAnalyticsProperties({
        updates: {
          switched_account: false,
        },
      }),
    );
  }
}
