import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'om-program-banner',
  templateUrl: './program-banner.component.html',
  styleUrls: ['./program-banner.component.scss'],
})
export class ProgramBannerComponent implements OnInit, OnDestroy {
  @Input() programName: string;
  @Input() programImage: string;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}
