import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'om-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
  @Output() tooltipShown = new EventEmitter();
  @Input() text: string;
  @Input() placement: 'top' | 'right' | 'bottom' | 'left' | 'auto' = 'auto';
  @Input() label: string;
}
