import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FetchResult } from '@apollo/client/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { HomePageAnalyticsService } from '@app/home/home-page-analytics.service';
import { SendAppLink } from '@app/shared/download-app-card/__generated__/SendAppLink';
import { SendAppLinkGraphQLService } from '@app/shared/download-app-card/send-app-link-graphql.service';
import { PatientPhoneNumbersGraphQLService } from '@app/shared/download-app-modal/patient-phone-numbers-graphql.service';
import { ToastService } from '@app/shared/toast.service';
import { OmguiButtonVariant } from '@omgui/omgui-button/omgui-button.component';

import { PatientPhoneNumberKind } from '../../../../__generated__/globalTypes';

@Component({
  selector: 'om-download-app-modal-content',
  templateUrl: './download-app-modal.component.html',
  styleUrls: ['./download-app-modal.component.scss'],
})
export class DownloadAppModalContent implements OnInit, OnDestroy {
  formGroup: UntypedFormGroup;
  formControlName = 'phoneNumber';
  formErrorMessage: string;
  submitting = false;

  private destroy$ = new Subject();

  readonly ButtonVariant = OmguiButtonVariant;

  constructor(
    public modal: NgbActiveModal,
    public linksService: LinksService,
    private patientPhoneNumbersGraphQLService: PatientPhoneNumbersGraphQLService,
    private sendAppLinkGraphQLService: SendAppLinkGraphQLService,
    private toastService: ToastService,
    private homePageAnalyticsService: HomePageAnalyticsService,
  ) {}

  ngOnInit(): void {
    const phoneNumberControl = new UntypedFormControl({ value: undefined, disabled: false }, [Validators.required]);

    this.formGroup = new UntypedFormGroup({
      [this.formControlName]: phoneNumberControl,
    });

    this.patientPhoneNumbersGraphQLService
      .watch()
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe({
        next: result => {
          const { phoneNumbers } = result.data.patient.profileInfo;

          let targetNumber = phoneNumbers.find(phoneNumber => phoneNumber.kind === PatientPhoneNumberKind.mobile);
          if (!targetNumber) {
            targetNumber = phoneNumbers.find(phoneNumber => phoneNumber.isPreferred);
          }

          phoneNumberControl.setValue(targetNumber.number);
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit() {
    if (this.submitting) {
      return;
    }

    this.submitting = true;
    this.homePageAnalyticsService.trackSendDownloadLinkClicked();

    this.sendAppLinkGraphQLService
      .mutate({
        input: { phoneNumber: this.formGroup.controls[this.formControlName].value },
      })
      .subscribe({
        next: (response: FetchResult<SendAppLink>) => {
          const { success, errors } = response.data.sendAppLink;

          if (success) {
            this.toastService.push('Link Sent');
            this.modal.close();
          } else {
            this.setErrorMessage(errors.join(', '));
          }
        },
        error: (error: Error) => {
          this.setErrorMessage(error.message);
        },
      });
  }

  setErrorMessage(errorMessage: string): void {
    this.submitting = false;
    this.formErrorMessage = errorMessage;
  }
}

@Component({
  selector: 'om-download-app-modal',
  template: ``,
})
export class DownloadAppModalComponent {
  constructor(private modalService: NgbModal, private homePageAnalyticsService: HomePageAnalyticsService) {}

  open() {
    const modalReference = this.modalService.open(DownloadAppModalContent, {
      centered: true,
      modalDialogClass: 'modal-redesign no-sm-rounded-bottom',
      ariaLabelledBy: 'download-app',
    });

    modalReference.dismissed.pipe(take(1)).subscribe(() => this.homePageAnalyticsService.trackDownloadAppModalClosed());
  }
}
