import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { AuthService } from '@app/core/auth.service';
import { LegalDocumentsService } from '@app/core/legal-documents';
import { MembershipService } from '@app/core/membership.service';

@Injectable()
export class TosGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private attemptedPathService: AttemptedPathService,
    private membershipService: MembershipService,
    private router: Router,
    private legalDocumentsService: LegalDocumentsService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // avoid further checks if logged out - AuthGuardService already handles it,
    // continuing would end up with a 401 from 1life & another redirect to login, which would drop attemptedPath from cookies
    const authInitializedCheck = this.authService.initialized$.pipe(
      map(() => {
        if (this.authService.isTokenBlank()) {
          return false;
        }
        return true;
      }),
    );

    const runTosCheck = () => {
      this.membershipService.getMembership();

      return combineLatest([
        this.legalDocumentsService.getForSelf().valueChanges,
        this.membershipService.membership$,
      ]).pipe(
        map(([tos, membership]) => {
          const unsignedDocs = tos.filter(doc => !doc.signed);
          if (unsignedDocs.length && membership.isActive) {
            this.attemptedPathService.setAttemptedPath(state.url);
            this.router.navigate(['terms-of-service'], { replaceUrl: true });
            return false;
          }
          return true;
        }),
      );
    };

    return authInitializedCheck.pipe(concatMap(authCheck => (authCheck ? runTosCheck() : observableOf(false))));
  }
}
