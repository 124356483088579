import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { LinksService } from '@app/core/links.service';
import { assignOnboardingPromptExperimentVariation, showOnboardingPrompts } from '@app/onboarding/onboarding.actions';

export const EXPERIMENT_OFF = 'OFF';
export const EXPERIMENT_ON_SHOW_ONBOARDING_PROMPTS = 'ON VARIANT';
export const EXPERIMENT_ON_HIDE_ONBOARDING_PROMPTS = 'ON CONTROL';

@Injectable({
  providedIn: 'root',
})
export class OnboardingStepService {
  constructor(
    private featureFlagSelectors: FeatureFlagSelectors,
    private router: Router,
    private links: LinksService,
    private store: Store,
  ) {}

  determineNextStep(source: string): void {
    this.featureFlagSelectors
      .getFeatureFlag(FeatureFlags.PC_ONBOARDING_PROMPTS_EXPERIMENT, EXPERIMENT_OFF)
      .pipe(take(1))
      .subscribe(onboardingPromptsEnabled => {
        this.store.dispatch(
          assignOnboardingPromptExperimentVariation({
            experiment_name: FeatureFlags.PC_ONBOARDING_PROMPTS_EXPERIMENT,
            variation_name: onboardingPromptsEnabled,
          }),
        );
        if (onboardingPromptsEnabled === EXPERIMENT_ON_SHOW_ONBOARDING_PROMPTS) {
          this.redirectToHomePage();
          this.store.dispatch(showOnboardingPrompts());
        } else {
          this.redirectToOnboarding(source);
        }
      });
  }

  private redirectToHomePage() {
    this.router.navigate([this.links.home]);
  }

  private redirectToOnboarding(source: string) {
    this.router.navigate([this.links.onboarding], {
      queryParams: { source: source },
    });
  }
}
