import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { combineLatest, Observable, of as observableOf } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  MembershipResult,
  MembershipResult_membership as Membership,
  MembershipResult_membership_plan_B2bPlan as B2bPlan,
} from '@app/core/__generated__/MembershipResult';
import { AuthService } from '@app/core/auth.service';
import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';
import { MembershipPlan } from '@app/core/membership';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { UserService } from '@app/core/user.service';
import { EnterpriseRegistration } from '@app/registration/enterprise';
import { User } from '@app/shared/user';

export enum PediatricMembershipTypes {
  Enterprise = 'Enterprise',
  Consumer = 'Consumer',
  PrepaidEnrollment = 'PrepaidEnrollment',
}

@Injectable()
export class PediatricMembershipTypeService {
  constructor(
    private enterpriseRegistration: EnterpriseRegistration,
    private authService: AuthService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private membershipGraphQL: MembershipGraphQL,
    private userService: UserService,
    private featureFlagSelectors: FeatureFlagSelectors,
  ) {}

  get isEnterprise$(): Observable<boolean> {
    if (this.authService.isLoggedIn()) {
      return this.isMembershipCorePlusEnterprise();
    } else if (this.enterpriseRegistration.b2bCompanyId) {
      return this.b2bCompanyIncludesDependent();
    } else {
      return observableOf(false);
    }
  }

  get isPrepaidEnrollment$(): Observable<boolean> {
    return this.featureFlagSelectors.getFeatureFlag(FeatureFlags.HORNBILL_ENROLLMENT_P0_ENABLED, false).pipe(
      map(enabled => {
        if (enabled) {
          if (this.authService.isLoggedIn()) {
            // TODO: Validate logged in subscriber is subscription manager/has seats to create child/this is subject to change
            return enabled; // has query param manager or seatholder
          } else {
            // TODO: Validate Claim code
            return !!this.activatedRoute.snapshot.queryParams.claim_code;
          }
        }
        return false;
      }),
    );
  }

  get pediatricMembershipRegType$(): Observable<PediatricMembershipTypes> {
    return combineLatest([this.isEnterprise$, this.isPrepaidEnrollment$]).pipe(
      take(1),
      map(([isEnterprise, isPrepaidEnrollment]) => {
        // TODO: error state if both are true
        if (isEnterprise) {
          return PediatricMembershipTypes.Enterprise;
        } else if (isPrepaidEnrollment) {
          return PediatricMembershipTypes.PrepaidEnrollment;
        } else {
          return PediatricMembershipTypes.Consumer;
        }
      }),
    );
  }

  isCorePlusEnterprise(membership: Membership): boolean {
    return membership.planType === MembershipPlan.B2B && (membership.plan as B2bPlan).company.includesDependent;
  }

  private b2bCompanyIncludesDependent(): Observable<boolean> {
    const { b2bCompany } = this.enterpriseRegistration;
    return observableOf(b2bCompany && b2bCompany.includesDependent && b2bCompany.enterprisePedsRegistrationEnabled);
  }

  private isMembershipCorePlusEnterprise(): Observable<boolean> {
    this.userService.getUser();
    const user$ = this.userService.user$;

    return combineLatest([user$, this.membershipGraphQL.fetch()]).pipe(
      take(1),
      map(([user, membershipResponse]: [User, ApolloQueryResult<MembershipResult>]) => {
        const { membership } = membershipResponse.data;
        const { whitelistedEmployee } = user;
        const pediatricFeatureEnabled =
          (membership.plan as B2bPlan)?.company?.enterprisePedsRegistrationEnabled || false;

        if (whitelistedEmployee) {
          return this.isCorePlusEnterprise(membership);
        }

        return (
          pediatricFeatureEnabled &&
          this.isCorePlusEnterprise(membership) &&
          (membership.plan as B2bPlan).hasActiveDiscountTypes
        );
      }),
    );
  }
}
