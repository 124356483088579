<ng-container *ngIf="dataReady$ | async">
  <om-navbar-desktop
    class="d-none d-md-block"
    [currentUser]="currentUser$ | async"
    [principalUser]="principalUser$ | async"
    [targetUsers]="targetUsers$ | async"
    [canBookVisit]="canBookVisit$ | async"
    [canRegisterKid]="canRegisterKid$ | async"
    [enrolledInMindsetPlus]="enrolledInMindsetPlus$ | async"
    [inactiveMembership]="inactiveMembership$ | async"
    [inviteCta]="inviteCta$ | async"
    (principalUserClicked)="resetTargetUser()"
    (targetUserClicked)="setTargetUser($event)"
  ></om-navbar-desktop>
  <om-navbar-mobile
    class="d-block d-md-none"
    [currentUser]="currentUser$ | async"
    [principalUser]="principalUser$ | async"
    [targetUsers]="targetUsers$ | async"
    [canBookVisit]="canBookVisit$ | async"
    [canRegisterKid]="canRegisterKid$ | async"
    [enrolledInMindsetPlus]="enrolledInMindsetPlus$ | async"
    [inactiveMembership]="inactiveMembership$ | async"
    [inviteCta]="inviteCta$ | async"
    (principalUserClicked)="resetTargetUser()"
    (targetUserClicked)="setTargetUser($event)"
  ></om-navbar-mobile>
</ng-container>
