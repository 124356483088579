<a
  class="d-inline-block"
  [attr.aria-label]="label"
  href="#"
  (click)="$event.preventDefault()"
  (focus)="toolTip.open()"
  (blur)="toolTip.close()"
>
  <om-svg-info
    height="18"
    width="18"
    stroke="currentColor"
    [className]="toolTip.isOpen() ? 'tooltip-on' : 'tooltip-off'"
    #toolTip="ngbTooltip"
    [ngbTooltip]="text"
    [placement]="placement"
    triggers="click:blur hover:blur"
    (shown)="tooltipShown.emit()"
  ></om-svg-info>
</a>
