import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiHeaderService } from './api-header.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private apiHeaderService: ApiHeaderService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.apiHeaderService.token;

    if (authToken) {
      const authReq = req.clone({ setHeaders: { Authorization: `Bearer ${authToken}` } });
      return next.handle(authReq);
    }

    return next.handle(req);
  }
}
