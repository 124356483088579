import { Injectable } from '@angular/core';
import { gql } from '@apollo/client';
import { Query } from 'apollo-angular';

import { UserForLoggedInWrapperInitialization } from '@app/shared/logged-in-wrapper/__generated__/user-for-logged-in-wrapper-initialization-graphql.service.types';

@Injectable({
  providedIn: 'root',
})
export class UserForLoggedInWrapperInitializationGraphQLService extends Query<UserForLoggedInWrapperInitialization> {
  document = gql`
    query UserForLoggedInWrapperInitialization {
      principalUser {
        id
      }
      patient {
        id
      }
    }
  `;
}
