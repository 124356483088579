<label class="form-label omgui-checkbox-container" [class.disabled]="disabled">
  <input
    type="checkbox"
    [id]="inputId"
    [attr.name]="name"
    [attr.value]="value"
    [attr.disabled]="disabled ? 'disabled' : null"
    [(ngModel)]="_checked"
    (ngModelChange)="handleChange($event)"
    (blur)="handleTouched()"
  /><span class="omgui-checkbox-checkmark"> </span
  ><span [class.label-with-subtitle]="subtitle">{{ label && label.trim() }}</span>
  <div *ngIf="subtitle" class="label-subtitle">{{ subtitle }}</div>
</label>
