import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  static RUNNING_LATE = "You've been marked as running late to your appointment";
  static APPOINTMENT_CANCELED = 'Your appointment has been canceled';

  private _toastMessage$ = new Subject<string>();
  toastMessage$ = this._toastMessage$.asObservable();

  push(message: string) {
    this._toastMessage$.next(message);
  }
}
