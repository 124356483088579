<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_852_11210)">
    <path
      d="M12.3924 8.71268L9.25406 5.57437C8.47301 4.79332 7.20668 4.79332 6.42563 5.57437L5.07755 6.92245C3.96075 8.03925 3.18313 9.48281 3.41232 11.0455C3.7708 13.4898 5.15479 17.5853 9.9282 22.3587C14.7016 27.1321 18.7971 28.5161 21.2414 28.8746C22.8041 29.1038 24.2476 28.3261 25.3644 27.2093L26.7125 25.8613C27.4936 25.0802 27.4936 23.8139 26.7125 23.0328L23.5742 19.8945C22.7932 19.1135 21.5268 19.1135 20.7458 19.8945L19.5854 21.0549C19.1264 21.5139 18.5184 21.8094 17.8901 21.646C16.9689 21.4065 15.4468 20.7172 13.5082 18.7787C11.5697 16.8401 10.8804 15.318 10.6409 14.3968C10.4775 13.7685 10.773 13.1605 11.232 12.7015L12.3924 11.5411C13.1734 10.7601 13.1734 9.49372 12.3924 8.71268Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.98369 5.13155C7.00881 4.10642 8.67087 4.10642 9.696 5.13155L12.8343 8.26985C13.8594 9.29498 13.8594 10.957 12.8343 11.9822L11.6739 13.1425C11.2964 13.5201 11.1606 13.911 11.2458 14.2386C11.4526 15.0343 12.0762 16.4618 13.9502 18.3358C15.8242 20.2098 17.2517 20.8334 18.0474 21.0403C18.375 21.1254 18.766 20.9896 19.1435 20.6121L20.3038 19.4517C21.329 18.4266 22.991 18.4266 24.0162 19.4517L27.1545 22.59C28.1796 23.6151 28.1796 25.2772 27.1545 26.3023L25.8064 27.6504C24.6173 28.8395 22.9871 29.7614 21.1507 29.4921C18.5664 29.113 14.3496 27.663 9.48626 22.7998C4.62297 17.9365 3.17296 13.7197 2.79393 11.1353C2.5246 9.29894 3.44654 7.6687 4.63561 6.47962L5.98369 5.13155ZM8.81212 6.01543C8.27514 5.47846 7.40454 5.47846 6.86757 6.01543L5.51949 7.36351C4.47496 8.40804 3.84165 9.66492 4.0307 10.9539C4.36864 13.2581 5.68662 17.2323 10.3701 21.9159C15.0537 26.5994 19.0279 27.9174 21.3321 28.2553C22.6211 28.4444 23.878 27.8111 24.9225 26.7665L26.2706 25.4184C26.8076 24.8815 26.8076 24.0109 26.2706 23.4739L23.1323 20.3356C22.5953 19.7986 21.7247 19.7986 21.1877 20.3356L20.0273 21.496C19.4869 22.0364 18.6617 22.4915 17.7329 22.25C16.686 21.9779 15.0693 21.2227 13.0663 19.2197C11.0633 17.2167 10.3082 15.6 10.036 14.5532C9.79447 13.6243 10.2496 12.7991 10.79 12.2587L11.9504 11.0983C12.4874 10.5613 12.4874 9.69071 11.9504 9.15374L8.81212 6.01543Z"
      fill="#004D49"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.4743 8.3582C17.4721 8.35851 17.47 8.3588 17.4678 8.35909C17.4672 8.35915 17.4669 8.35919 17.4669 8.35919L17.4671 8.35917L17.4697 8.35884L17.472 8.35852L17.4735 8.35832L17.4743 8.3582ZM23.9271 14.8184C23.8827 15.1573 24.1192 15.4697 24.4585 15.5182C24.8002 15.567 25.1167 15.3295 25.1656 14.9878L24.5577 14.901C25.1656 14.9878 25.1656 14.9874 25.1657 14.9869L25.1658 14.986L25.1661 14.984L25.1667 14.9793L25.1682 14.9676C25.1692 14.9588 25.1704 14.9479 25.1717 14.935C25.1742 14.9092 25.1768 14.8753 25.1787 14.8336C25.1826 14.7504 25.1838 14.6363 25.1754 14.4945C25.1587 14.2105 25.1041 13.8166 24.9583 13.3377C24.6656 12.376 24.0127 11.0948 22.6021 9.68412C21.1914 8.27346 19.9102 7.62058 18.9485 7.3279C18.4696 7.18214 18.0757 7.12749 17.7917 7.11078C17.6499 7.10244 17.5358 7.10358 17.4526 7.10747C17.4109 7.10942 17.377 7.11205 17.3512 7.11452C17.3383 7.11576 17.3274 7.11696 17.3186 7.11802L17.3069 7.11948L17.3022 7.1201L17.3002 7.12039L17.2993 7.12052C17.2988 7.12058 17.2984 7.12065 17.3868 7.73937L17.2984 7.12065C16.9567 7.16946 16.7192 7.48604 16.768 7.82775C16.8165 8.16702 17.1289 8.40349 17.4678 8.35909C17.4684 8.35902 17.4693 8.35893 17.4705 8.35882C17.4766 8.35823 17.4902 8.35708 17.5109 8.35611C17.5524 8.35417 17.6224 8.35298 17.7183 8.35862C17.9098 8.36989 18.2058 8.40847 18.5846 8.52375C19.3384 8.75316 20.4438 9.29363 21.7182 10.568C22.9926 11.8424 23.533 12.9478 23.7625 13.7016C23.8777 14.0804 23.9163 14.3764 23.9276 14.5679C23.9332 14.6638 23.932 14.7338 23.9301 14.7753C23.9291 14.796 23.928 14.8096 23.9274 14.8157C23.9273 14.8169 23.9272 14.8178 23.9271 14.8184Z"
      fill="#004D49"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.071 4.18144C18.0696 4.18163 18.0683 4.18182 18.0669 4.182L18.0668 4.18202C18.0665 4.18205 18.0664 4.18207 18.0664 4.18207C18.0664 4.18207 18.0666 4.18204 18.0671 4.18197L18.0689 4.18173L18.0702 4.18155L18.071 4.18144ZM28.1042 14.2191L28.1042 14.2193C28.0583 14.5591 28.2951 14.8729 28.6352 14.9214C28.9769 14.9703 29.2935 14.7328 29.3423 14.3911L28.7236 14.3027C29.3423 14.3911 29.3423 14.3907 29.3424 14.3902L29.3425 14.3892L29.3428 14.387L29.3436 14.3818L29.3453 14.3679C29.3466 14.357 29.3481 14.3431 29.3497 14.3262C29.353 14.2924 29.3566 14.2466 29.3592 14.1894C29.3646 14.075 29.3663 13.9149 29.3545 13.7136C29.3308 13.3108 29.2528 12.7444 29.0418 12.051C28.6186 10.6605 27.6674 8.78261 25.5855 6.7007C23.5036 4.61879 21.6256 3.66757 20.2351 3.24437C19.5418 3.03335 18.9754 2.95538 18.5725 2.93169C18.3713 2.91985 18.2112 2.92157 18.0967 2.92692C18.0395 2.92959 17.9937 2.93317 17.9599 2.93641C17.943 2.93803 17.9291 2.93957 17.9183 2.94087L17.9044 2.94261L17.8991 2.94331L17.8969 2.94362L17.896 2.94375C17.8955 2.94382 17.8951 2.94388 17.9834 3.5626L17.8951 2.94388C17.5533 2.9927 17.3159 3.30928 17.3647 3.65099C17.4133 3.99106 17.7271 4.22786 18.0669 4.182L18.0793 4.18071C18.0933 4.17936 18.1188 4.17726 18.1551 4.17556C18.2277 4.17216 18.3438 4.17039 18.4991 4.17953C18.8095 4.19779 19.278 4.25967 19.8712 4.44021C21.0538 4.80015 22.7559 5.63895 24.7016 7.58458C26.6472 9.53022 27.486 11.2323 27.846 12.415C28.0265 13.0082 28.0884 13.4766 28.1066 13.787C28.1158 13.9424 28.114 14.0585 28.1106 14.1311C28.1089 14.1674 28.1068 14.1928 28.1055 14.2069L28.1042 14.2191ZM28.1047 14.2152C28.1047 14.2152 28.1047 14.2152 28.1047 14.2152L28.1047 14.2153C28.1047 14.2152 28.1047 14.2152 28.1047 14.2152ZM28.1047 14.2153L28.1046 14.2159L28.1044 14.2173L28.1042 14.2191C28.1041 14.2198 28.1041 14.22 28.1041 14.2196L28.1041 14.2194L28.1042 14.2193C28.1043 14.2179 28.1045 14.2166 28.1047 14.2153Z"
      fill="#004D49"
    />
  </g>
  <defs>
    <clipPath id="clip0_852_11210">
      <rect width="32" height="32" fill="white" />
    </clipPath>
  </defs>
</svg>
