import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LDFlagChangeset } from 'launchdarkly-js-sdk-common';
import mapValues from 'lodash-es/mapValues';
import { combineLatest, of as observableOf } from 'rxjs';
import { map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { State } from '@app/app.reducer';
import { userLoggedIn, userLoggedOut } from '@app/auth/auth.actions';
import { AnalyticsService } from '@app/core/analytics.service';
import {
  loadFeatureFlag,
  setFlags,
  updateAnonUserWithCustomAttributes,
  updateUserWithCustomAttributes,
} from '@app/core/feature-flags/feature-flag.actions';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import { MembershipService } from '@app/core/membership.service';
import { UserService } from '@app/core/user.service';
import {
  registerLocationSuccess,
  registerPaymentInfoSuccess,
  registerPersonalInfoSuccess,
  registerRestart,
} from '@app/registration/consumer/consumer-registration.actions';

@Injectable()
export class FeatureFlagEffects {
  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private launchDarklyService: LaunchDarklyService,
    private userService: UserService,
    private membershipService: MembershipService,
    private analyticsService: AnalyticsService,
  ) {}

  /**
   * When changes are made in the LaunchDarkly UI, we can react to the updates in real time
   */
  listenForOnChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(_action =>
        this.launchDarklyService.onChange$.pipe(
          map((flags: LDFlagChangeset) =>
            setFlags({
              flags: mapValues(flags, 'current'),
            }),
          ),
        ),
      ),
    ),
  );

  /**
   * When we select a given feature flag, if it is not already in the store, we get the value directly from LauchDarkly's client
   * and then save it in the store.
   */
  setFeatureFlag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFeatureFlag),
      mergeMap(action =>
        this.launchDarklyService.featureFlag$(action.flag, action.defaultValue).pipe(
          tap(value => {
            this.analyticsService.onFeatureFlagEvaluated(action.flag, value);
          }),
          map(value => setFlags({ flags: { [action.flag]: value } })),
        ),
      ),
    ),
  );

  /**
   * Identify the user soon as they login
   */
  updateLaunchDarklyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          userLoggedIn,
          registerPaymentInfoSuccess,
          registerLocationSuccess,
          registerPersonalInfoSuccess,
          registerRestart,
        ),
        switchMap(() => combineLatest([this.userService.getUser(true), this.membershipService.getMembership()])),
        tap(([user, membership]) => {
          this.launchDarklyService.updateUser({ user, membership });
        }),
      ),
    { dispatch: false },
  );

  /**
   * Identify the user with custom attributes
   */
  updateLaunchDarklyUserWithCustomAttributes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateUserWithCustomAttributes),
        switchMap(action =>
          combineLatest([
            observableOf(action.customAttributes),
            this.userService.getUser(),
            this.membershipService.getMembership(),
          ]),
        ),
        tap(([customAttributes, user, membership]) => {
          this.launchDarklyService.updateUser({ user, membership, customAttributes });
        }),
      ),
    { dispatch: false },
  );

  /**
   * Identify the an anon user with custom attributes
   */
  updateLaunchDarklyAnonUserWithCustomAttributes$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateAnonUserWithCustomAttributes),
        tap(action => this.launchDarklyService.updateAnonUserCustomAttributes(action.customAttributes)),
      ),
    { dispatch: false },
  );

  /**
   * Set the user back to anonymous when they log out
   */
  resetLaunchDarklyUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userLoggedOut),
        tap(() => {
          this.launchDarklyService.resetUser();
        }),
      ),
    { dispatch: false },
  );
}
