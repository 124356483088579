import { Component, OnInit } from '@angular/core';
import { finalize, onErrorResumeNext, share } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';
import { ApiService } from '@app/core/api.service';
import { AuthService } from '@app/core/auth.service';

@Component({
  selector: 'om-logout',
  template: '',
})
export class Logout implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    private apiService: ApiService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.analyticsService.resetMixpanelId();
    const request = this.apiService.delete('/api/v2/patient/logout').pipe(
      onErrorResumeNext(),
      finalize(() => this.authService.logout()),
      share(),
    );
    request.subscribe();
  }
}
