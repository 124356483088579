import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { Pcp as PcpResult } from '@app/shared/__generated__/Pcp';

@Injectable({
  providedIn: 'root',
})
export class PcpGraphQL extends Query<PcpResult> {
  document = gql`
    query Pcp {
      patient {
        id
        pcp {
          id
          displayName
          profileImageUrl
        }
      }
    }
  `;
}
