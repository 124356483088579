import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import Rollbar from 'rollbar';
import { Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { userLoggedIn, userLoggedOut } from '@app/auth/auth.actions';

import { ApiHeaderService } from './api-header.service';
import { Auth0AuthService } from './auth0-auth.service';
import { OnelifeAuthService } from './onelife-auth.service';
import { RollbarService } from './rollbar.service';

export interface AuthServiceImplementation {
  /**
   * fetch the token from the implementation
   * not meant to be done on every request (I understand it to be an api call)
   */
  getToken(): Observable<string>;

  /**
   * logout should remove authorization and
   * redirect the user to an appropriate location, e.g. '/login' by default
   */
  logout(returnURI?: string, loginBeforeRedirect?: boolean): void;

  /**
   * set the token in app, and hook into anything required in the implementation
   */
  setToken(token: string): void;

  /**
   * Initialize() - method to initialize the logged in state based upon the implementation
   */
  init(): Observable<boolean>;

  /**
   * navigate to login, return to this url (else '/')
   */
  goLogin(path: string | null): void;
}

@Injectable({
  providedIn: 'root',
})
/**
 * Class AuthService - parent, the api interface that can hande auth, exposed to all the apps
 */
export class AuthService implements AuthServiceImplementation {
  implementation: AuthServiceImplementation;
  private _initialized$ = new ReplaySubject<boolean>(1);

  constructor(
    private apiHeaderService: ApiHeaderService,
    private auth0AuthService: Auth0AuthService,
    private onelifeAuthService: OnelifeAuthService,
    @Inject(RollbarService) private rollbar: Rollbar,
    private store: Store,
  ) {}

  get initialized$(): Observable<boolean> {
    return this._initialized$.asObservable();
  }

  getToken() {
    // consider updating local token stash in apiHeaderService
    const getToken$ = this.initialized$.pipe(switchMap(() => this.implementation.getToken()));
    getToken$.subscribe();
    return getToken$;
  }

  goLogin(path: string = null) {
    this.apiHeaderService.revokeAccessToken();
    this.initialized$.pipe(tap(() => this.implementation.goLogin(path))).subscribe();
  }

  init() {
    const isConfigured = this.auth0AuthService.isConfigured();
    const useAuth0 = isConfigured && !(<any>window)?.Cypress?.env('forceLegacyAuth');
    this.implementation = useAuth0 ? this.auth0AuthService : this.onelifeAuthService;

    if (!isConfigured) {
      const message = 'Missing Auth0 configuration';
      try {
        this.rollbar.error(message);
      } catch (error) {
        console.error('Rollbar failure to report: ');
        console.error(message);
      }
    }

    this.implementation
      .init()
      .pipe(
        switchMap(() => this.implementation.getToken()),
        map(token => {
          this.setToken(token);
          this._initialized$.next(true);
        }),
      )
      .subscribe();

    return this.initialized$;
  }

  logout(returnURI?: string, loginBeforeRedirect = true): void {
    this.apiHeaderService.revokeAccessToken();
    const logout$ = this.initialized$.pipe(map(() => this.implementation.logout(returnURI, loginBeforeRedirect)));
    logout$.subscribe();
    this.store.dispatch(userLoggedOut());
  }

  setToken(token: string) {
    if (!token) {
      this.apiHeaderService.revokeAccessToken();
      return;
    }
    this.apiHeaderService.setAccessToken(token);
    this.implementation.setToken(token);
    this.store.dispatch(userLoggedIn());
  }

  isTokenBlank() {
    return !this.apiHeaderService.token;
  }

  isLoggedIn() {
    return !!this.apiHeaderService.token;
  }
}
