import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { LinksService } from '@app/core/links.service';
import { Membership } from '@app/core/membership';
import { MembershipService } from '@app/core/membership.service';
import { MembershipAnalyticsService } from '@app/membership/membership-analytics.service';

import { MembershipExpirationHelper } from '../membership-expiration-helper';

@Component({
  selector: 'om-expired-membership-banner',
  templateUrl: './expired-membership-banner.component.html',
  styleUrls: ['./expired-membership-banner.component.scss'],
})
export class ExpiredMembershipBannerComponent implements OnInit, OnDestroy {
  path: string;
  membership: Membership;
  formattedExpiryString: string;
  expirationDate: string;
  trackExpiredMembershipBannerClicked = this.analytics.expiredMembershipBannerClicked.bind(this.analytics);
  trackExpiringB2bMembershipBannerClicked = this.analytics.expiringB2bMembershipBannerClicked.bind(this.analytics);
  trackReactivateMembershipBannerClicked = this.analytics.reactivateMembershipBannerClicked.bind(this.analytics);
  trackInvalidCCMembershipBannerClicked = this.analytics.invalidCCMembershipBannerClicked.bind(this.analytics);

  private closeSubscriptions = new Subject();

  constructor(
    private membershipService: MembershipService,
    private links: LinksService,
    private analytics: MembershipAnalyticsService,
    private router: Router,
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(takeUntil(this.closeSubscriptions))
      .subscribe((_: NavigationEnd) => (this.path = router.url));
  }

  ngOnInit() {
    this.membershipService.membership$.pipe(takeUntil(this.closeSubscriptions)).subscribe((membership: Membership) => {
      this.membership = membership;
      const membershipExpirationHelper = new MembershipExpirationHelper(this.membership);
      this.formattedExpiryString = membershipExpirationHelper.formattedExpiry();
      const isTrial = this.membership.trialUntil || false;
      this.expirationDate = membershipExpirationHelper.expirationDateString();
      if (isTrial) {
        this.expirationDate = membershipExpirationHelper.freeTrialExpirationDateString();
      }
    });

    this.membershipService.getMembership();
  }

  canDisplayExpiredBanner() {
    return (
      this.path !== '/membership/renew/consumer' &&
      this.path !== '/membership/renew/enterprise' &&
      this.path !== '/membership/renew/omnow' &&
      this.path !== '/membership/settings'
    );
  }

  canDisplayAutoRenewBanner() {
    return this.path !== '/membership/settings';
  }

  ngOnDestroy(): void {
    this.closeSubscriptions.next();
    this.closeSubscriptions.complete();
  }
}
