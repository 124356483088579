import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class ForgotPasswordGuardService implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.authService.initialized$.pipe(
      map(() => {
        if (this.authService.isLoggedIn()) {
          this.router.navigate(['/']);
          return false;
        }

        return true;
      }),
    );
  }
}
