<omgui-feature-card>
  <omgui-feature-card-content
    [title]="data.title"
    [imageUrl]="data.imageUrl"
    [imageAltText]="data.imageAltText"
    [description]="data.description"
  >
    <ng-template omgui-feature-card-primary-action>
      <omgui-button
        *ngIf="data.primaryActionLabel"
        [externalLink]="data.primaryActionUrl"
        [newTab]="true"
        class="px-3 me-3 me-sm-0"
        [trackLink]="trackPrimaryLinkClicked()"
        data-cy="card-action-primary"
        >{{ data.primaryActionLabel }}<omgui-external-link-icon class="d-inline-flex ms-2"></omgui-external-link-icon>
      </omgui-button>
    </ng-template>
    <ng-template omgui-feature-card-secondary-action>
      <omgui-button
        *ngIf="data.secondaryActionLabel"
        [variant]="OmguiButtonVariant.link"
        (buttonClick)="secondaryAction.emit($event)"
        data-cy="card-action-secondary"
        >{{ data.secondaryActionLabel }}</omgui-button
      >
    </ng-template>
  </omgui-feature-card-content>
</omgui-feature-card>
