<ngb-accordion class="program-faqs">
  <ngb-panel *ngFor="let faq of data.faqs; index as i">
    <ng-template ngbPanelHeader let-isOpen="opened">
      <button
        ngbPanelToggle
        class="btn btn-link stretched-link d-flex justify-content-between align-items-center faq-header"
        (click)="trackFaqClicked(i)"
        data-cy="faq-button"
        ><!-- flexbox to space out and vertically centers the chevron icon -->
        <h3 class="h5">{{ faq.question }}</h3>
        <i class="fa" [class.fa-chevron-down]="!isOpen" [class.fa-chevron-up]="isOpen"></i>
      </button>
    </ng-template>
    <ng-template ngbPanelContent>
      <div [innerHTML]="faq.htmlSafeAnswer"></div>
    </ng-template>
  </ngb-panel>
</ngb-accordion>
