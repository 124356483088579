import { Injectable } from '@angular/core';
import isNil from 'lodash-es/isNil';
import pickBy from 'lodash-es/pickBy';

import { AppointmentInventory } from '@app/appointment/provider-inventories';
import { ANALYTICS_TIMESTAMP_FORMAT } from '@app/core/analytics.service';
import { AppointmentAnalyticsBaseService } from '@app/core/appointment-analytics-base.service';
import {
  FLOW_ONBOARDING,
  FLOW_POST_REGISTRATION,
  MODULE_APPOINTMENT_CONFIRMATION_PAGE,
  MODULE_HOME_PAGE,
  MODULE_SCHEDULE_VISIT_PAGE,
  MP_EVENT_PAGE_VIEWED,
} from '@app/core/mixpanel.constants';
import { formatDate } from '@app/shared/date-format.pipe';

@Injectable({
  providedIn: 'root',
})
export class OnboardingAnalyticsService extends AppointmentAnalyticsBaseService {
  private commonProperties = {
    flow: FLOW_POST_REGISTRATION,
  };

  inventorySelected(
    inventory: AppointmentInventory,
    props?: {
      serviceArea?: string;
    },
  ) {
    return this.trackWithBookingAndSearchStates('Appointment Selected', {
      ...this.commonProperties,
      module: MODULE_SCHEDULE_VISIT_PAGE,
      service_area: props?.serviceArea,
      appointment_inventory_id: inventory.id,
      selected_date: formatDate(inventory.start_time, ANALYTICS_TIMESTAMP_FORMAT, inventory.timezone),
    });
  }

  appointmentBooked(appointmentId: number, inventoryId: number) {
    const trackProps = {
      ...this.commonProperties,
      appointment_id: appointmentId,
      appointment_inventory_id: inventoryId,
    };

    return super.trackWithBookingAndSearchStates('Appointment Booked', trackProps);
  }

  seeMoreAppointmentsClicked(inventoryCount: number, properties: { serviceArea?: string }) {
    const trackProps = {
      ...this.commonProperties,
      module: MODULE_SCHEDULE_VISIT_PAGE,
      service_area: properties.serviceArea,
      inventory_count: inventoryCount,
    };
    return super.trackWithBookingAndSearchStates('See More Appointments Clicked', trackProps);
  }

  bookAppointmentClicked(_inventory: AppointmentInventory) {
    return super.trackWithBookingAndSearchStates('Book Appointment Clicked', {
      ...this.commonProperties,
      module: MODULE_APPOINTMENT_CONFIRMATION_PAGE,
    });
  }

  bookingCancelled() {
    return super.trackWithBookingAndSearchStates('Appointment Booking Cancelled', {
      ...this.commonProperties,
      module: MODULE_APPOINTMENT_CONFIRMATION_PAGE,
    });
  }

  postRegBooking(props: { module?: string; source?: string; serviceArea?: string }) {
    return super.trackWithDefaultProperties(
      MP_EVENT_PAGE_VIEWED,
      pickBy(
        {
          flow: FLOW_POST_REGISTRATION,
          module: props.module,
          source: props.source,
          service_area: props.serviceArea,
        },
        v => !isNil(v),
      ),
    );
  }

  onboardingPromptClicked(props: { answer_type: string }) {
    return super.trackWithDefaultProperties('Onboarding Questions Prompt Answered', {
      flow: FLOW_ONBOARDING,
      module: MODULE_HOME_PAGE,
      answer_type: props.answer_type,
    });
  }

  onboardingActionClicked(eventName, props: { flow: string }) {
    return super.trackWithDefaultProperties(eventName, {
      flow: props.flow,
      module: MODULE_HOME_PAGE,
      submodule: 'Onboarding Questions Prompt',
    });
  }

  onboardingSecondaryActionClicked() {
    return super.trackWithDefaultProperties('Onboarding Questions Prompt Closed', {
      flow: FLOW_ONBOARDING,
      module: MODULE_HOME_PAGE,
    });
  }

  onboardingExperimentVariationAssigned(props: { experiment_name: string; variation_name: string }) {
    return super.trackWithDefaultProperties('Enterprise Onboarding Experiment Variation Assigned', {
      flow: FLOW_ONBOARDING,
      experiment_name: props.experiment_name,
      experiment_variation_name: props.variation_name,
    });
  }
}
