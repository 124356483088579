<svg [attr.width]="width" [attr.height]="height" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="16" cy="16" r="16" [attr.fill]="backgroundFill" [attr.fill-opacity]="backgroundOpacity" />
  <circle class="hover-state" cx="16" cy="16" r="16" [attr.fill]="fill" />
  <path
    d="M23 9L9 23"
    [attr.stroke]="stroke"
    [attr.stroke-width]="strokeWidth"
    [attr.stroke-linejoin]="strokeLinejoin"
  />
  <path
    d="M23 23L9 9"
    [attr.stroke]="stroke"
    [attr.stroke-width]="strokeWidth"
    [attr.stroke-linejoin]="strokeLinejoin"
  />
</svg>
