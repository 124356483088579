<div class="legal-document">
  <div class="doc-title" [class.signed]="signed">
    <a
      href="#"
      class="leading-chevron"
      [class.rotated]="!collapsed"
      (keyup.space)="toggleDoc($event)"
      (keyup.spacebar)="toggleDoc($event)"
      (click)="toggleDoc($event)"
    >
      <span>{{ doc.detailHint }}</span>
    </a>
    <button
      (click)="signDoc($event)"
      class="checkbox-lookalike"
      [disabled]="signed || !enabled"
      [class.checked]="signed"
      data-cy="sign-document"
    >
      <span class="disabled-unselected" *ngIf="!enabled" [class.disabled]="signed"></span>
      <span class="checkmark" *ngIf="!processing && enabled" [class.disabled]="signed"></span>
      <om-logo-spinner class="spinner" *ngIf="processing" size="28" color="#068466"></om-logo-spinner>
    </button>
  </div>
  <div class="doc-content" tabindex="0" [class.collapsed]="collapsed"> <div [innerHTML]="trustedText"></div> </div>
</div>
