import { SafeHtml } from '@angular/platform-browser';

import { Provider } from '@app/shared/provider';

export interface HubContentItem {
  title?: string;
  moduleName?: string;
  content?: (HubContentItem | HubCareOptionItem)[];

  // TODO: placeholder "type" to determine what component render the content with
  type?: 'card' | 'card-lg';
}

export interface HubCareOptionItem extends HubContentItem {
  description: string;
  imageUrl: string;
  imageAltText: string;
  primaryActionLabel?: string;
  primaryActionUrl?: string;
  secondaryActionLabel?: string;
  secondaryActionUrl?: string;
  modalContent?: HubModalData;
}

// Use the HubCareOptionItem as default values if not provided
export interface HubModalData {
  moduleName?: string;
  title?: string;
  description?: string;
  imageUrl?: string;
  imageAltText?: string;
  primaryActionLabel?: string;
  primaryActionType?: 'external' | 'internal';
  primaryActionUrl?: string;
}

export interface ProgramFaqItem {
  question: string;
  answer: string;
  htmlSafeAnswer?: SafeHtml;
  openOnLoad?: boolean;
}

export interface ProgramFaqs {
  faqs: ProgramFaqItem[];
}

export interface ProgramImmediateCareContact {
  title: string;
  contactValue: string;
  contactDisplay: string;
  protocol: 'tel' | 'mailto';
  tooltipText: string;
}
export interface ProgramImmediateCare {
  moduleName?: string;
  programImmediateCare: {
    immediateCareContacts: ProgramImmediateCareContact[];
  };
}

export interface ProgramCareTeam {
  role: string;
  provider: Provider;
  tooltipText: string;
}

export interface ProgramBanner {
  programImage: string;
}

// For Mixpanel Tracking
export const MESSAGE_MODULE = 'Message';
export const APPOINTMENTS_MODULE = 'Appointments';
export const FAQ_MODULE = 'FAQ';
export const WARMLINE_MODULE = 'Warmline';
export const HUMANEST_MODULE = 'Humanest';
export const THERAPY_MODULE = 'Therapy';
export const MEDICATION_MODULE = 'Medication';
export const SKILLS_MODULE = 'Skills';
