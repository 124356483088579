import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';

import { FeatureFlagSelectors } from '@app/core/feature-flags/feature-flag.selectors';
import { FeatureFlags } from '@app/core/feature-flags/feature-flags';

export interface ConsumerRegistrationDay1BannerDetails {
  discount_codes: string[];
}

@Component({
  selector: 'om-day1-banner',
  templateUrl: 'day1-banner.component.html',
  styleUrls: ['day1-banner.component.scss'],
})
export class Day1BannerComponent implements OnInit {
  bannerDetails = {
    content: '',
    type: 'info',
    visible: false,
    amountOff: 0,
    discountCode: '',
    discountType: 'Discount',
  };

  @Output() bannerData: EventEmitter<any> = new EventEmitter<any>();

  constructor(private activatedRoute: ActivatedRoute, private featureFlagSelectors: FeatureFlagSelectors) {}

  ngOnInit() {
    combineLatest([
      this.activatedRoute.queryParams,
      this.featureFlagSelectors.getFeatureFlag(FeatureFlags.CONSUMER_REGISTRATION_QUERY_PARAM, false),
      this.featureFlagSelectors.getFeatureFlag(
        FeatureFlags.CONSUMER_REGISTRATION_DAY_1_BANNER,
        {} as ConsumerRegistrationDay1BannerDetails,
      ),
    ]).subscribe(([queryParams, queryParamsFeatureFlag, day1BannerConfigFlag]) => {
      this.setupDay1Banner(queryParams, queryParamsFeatureFlag, day1BannerConfigFlag); // Day1 Banner takes presedence over any other discount codes.
    });
  }

  // Relies on a combination of several feature flags and config options to display such Day1 banner
  setupDay1Banner(
    queryParams: any,
    queryParamsFeatureFlag: boolean,
    day1BannerConfigFlag: ConsumerRegistrationDay1BannerDetails,
  ): void {
    if (
      queryParamsFeatureFlag &&
      day1BannerConfigFlag.discount_codes.length > 0 &&
      queryParams.discount_code &&
      day1BannerConfigFlag.discount_codes
        .map(discountCode => discountCode.toUpperCase())
        .includes(queryParams.discount_code.toUpperCase())
    ) {
      const text = `<b>and One Medical</b> have teamed up. Get your first year of membership for <s>$199</s> <b>$144</b> with code <b>${queryParams.discount_code.toUpperCase()}</b>`;

      this.bannerDetails = {
        content: text,
        type: 'WarningLight',
        visible: true,
        amountOff: 199 - 144, // $55 off
        discountCode: queryParams.discount_code.toUpperCase(),
        discountType: 'Discount',
      };

      this.bannerData.emit(this.bannerDetails);
    }
  }
}
