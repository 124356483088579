import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of as observableOf, Subject, throwError as observableThrowError } from 'rxjs';

import { MODULE_VERIFIED_ACCOUNT_SETUP_PAGE } from '@app/core/mixpanel.constants';
import { StepName } from '@app/registration/enterprise/registration-step-name';
import { Address } from '@app/shared/address';

import { EnterpriseRegistration } from '../enterprise-registration';
import { EnterpriseRegistrationAnalyticsService } from '../enterprise-registration-analytics.service';
import { RegistrationStep } from '../registration-step';
import { VerifiedAccountSetUpStepComponent } from './verified-account-set-up-step.component';

@Injectable()
export class VerifiedAccountSetUpConfig extends RegistrationStep {
  MODULE = MODULE_VERIFIED_ACCOUNT_SETUP_PAGE;
  component = VerifiedAccountSetUpStepComponent;
  progress = 5;
  showLoginLink = true;

  form: UntypedFormGroup = this.formBuilder.group({
    address: this.formBuilder.group(Address.buildBasicAddressControlsConfig()),
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    phoneNumber: '',
    preferredName: '',
    textAppToPhone: true,
  });

  private destroy$ = new Subject();

  constructor(
    private enterpriseRegistrationAnalyticsService: EnterpriseRegistrationAnalyticsService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  initComponent(component: VerifiedAccountSetUpStepComponent) {
    component.form = this.form;
  }

  onDestroy() {
    this.destroy$.next();
    this.form.controls.phoneNumber.clearValidators();
  }

  patchParams(params: Record<string, any>) {
    const { firstName, lastName, address, phoneNumber, preferredName } = params;
    if (firstName) {
      this.form.patchValue({ firstName });
    }
    if (lastName) {
      this.form.patchValue({ lastName });
    }
    if (address) {
      this.form.patchValue({ address });
    }
    if (phoneNumber) {
      this.form.patchValue({ phoneNumber });
    }
    if (preferredName) {
      this.form.patchValue({ preferredName });
    }
  }

  submit(state: EnterpriseRegistration): Observable<any> {
    if (this.form.controls.address.invalid) {
      this.enterpriseRegistrationAnalyticsService.invalidAddressSelected(
        '',
        state.isWhitelisted,
        MODULE_VERIFIED_ACCOUNT_SETUP_PAGE,
      );
    }

    if (this.form.valid) {
      state.patient.address = this.form.controls.address.value;
      state.patient.firstName = this.form.controls.firstName.value;
      state.patient.lastName = this.form.controls.lastName.value;
      state.patient.preferredName = this.form.controls.preferredName.value;
      state.patient.phoneNumber = this.form.controls.phoneNumber.value;
      state.setCurrentStep(StepName.dobSex);
      this.trackSubmission(state);
      return observableOf(true);
    }
    return observableThrowError(new Error());
  }

  private trackSubmission(state: EnterpriseRegistration) {
    this.enterpriseRegistrationAnalyticsService.regInputSubmitted({
      module: this.MODULE,
      isWhitelist: state.isWhitelisted,
    });
  }
}
