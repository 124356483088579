import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from './auth.service';

// The purpose of this guard service is to make sure the auth service is initialized before proceeding
@Injectable()
export class AuthInitializedGuardService implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.authService.initialized$.pipe(map(() => true));
  }
}
