import { HttpHeaders } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { InMemoryCache, ApolloLink, concat } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { ApiHeaderService } from '@app/core/api-header.service';
import { ConfigService } from '@app/core/config.service';
import { CoreModule } from '@app/core/core.module';
import { Lrmo } from '@app/core/lrmo';
import { TargetUserService } from '@app/core/target-user.service';

export function createApollo(
  httpLink: HttpLink,
  apiHeaderService: ApiHeaderService,
  targetUserService: TargetUserService,
  configService: ConfigService,
) {
  const uri = `${configService.json.apiServer}/api/graphql`;
  const http = httpLink.create({ uri });

  return {
    link: concat(apolloLinkMiddleware(apiHeaderService, targetUserService), http),
    cache: new InMemoryCache(),
  };
}

export function apolloLinkMiddleware(apiHeaderService: ApiHeaderService, targetUserService: TargetUserService) {
  return new ApolloLink((operation, forward) => {
    let headers = new HttpHeaders();

    const lrmoId = new Lrmo(localStorage).getId();
    if (lrmoId) {
      headers = headers.append('Lrmo-Id', lrmoId);
    }

    const { token } = apiHeaderService;
    if (token) {
      const authHeader = `Bearer ${token}`;
      headers = headers.append('Authorization', authHeader);
    }

    const targetUser = targetUserService.getTargetUser();
    if (targetUser) {
      headers = headers.append('X-Target-User', targetUser.fhirIdentifier);
    }

    operation.setContext({ headers });

    return forward(operation);
  });
}

@NgModule({
  imports: [CoreModule],
  exports: [],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ApiHeaderService, TargetUserService, ConfigService],
    },
  ],
})
export class GraphQLModule {}
