<div [ngClass]="{ 'border rounded': !borderless }">
  <h6 class="pt-3 title" *ngIf="title">{{ title }}</h6>

  <ul class="list-group list-group-flush d-none d-sm-block rounded">
    <li class="list-group-item d-flex align-items-center" data-cy="list-group-item" *ngFor="let item of items">
      <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
      <ng-container *ngIf="item.data.action?.route">
        <omgui-button
          class="col-3 px-2"
          [variant]="OmguiButtonVariant.secondary"
          [trackLink]="item.data.action.trackingFunction"
          [internalLink]="item.data.action.route"
          [queryParams]="item.data.action.queryParams"
          [size]="OmguiButtonSize.fillParent"
          >{{ item.data.action.label }}</omgui-button
        >
      </ng-container>
      <ng-container *ngIf="item.data.action?.href">
        <omgui-button
          class="col-3 px-2"
          [variant]="OmguiButtonVariant.secondary"
          [trackLink]="item.data.action.trackingFunction"
          [externalLink]="item.path"
          [size]="OmguiButtonSize.fillParent"
          >{{ item.data.action.label }}</omgui-button
        >
      </ng-container>
      <ng-container *ngIf="!item.data.action?.route && !item.data.action?.href && item.data.action?.label">
        <omgui-button
          class="col-3 px-2"
          [variant]="OmguiButtonVariant.secondary"
          (buttonClick)="item.buttonClick.emit($event)"
          [size]="OmguiButtonSize.fillParent"
          >{{ item.data.action.label }}</omgui-button
        >
      </ng-container>
    </li>
  </ul>

  <div class="list-group list-group-flush d-sm-none rounded">
    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="item.data.action?.route">
        <a
          class="list-group-item d-flex align-items-center list-group-item-action"
          [omTrackLink]="item.data.action.trackingFunction"
          [routerLink]="item.data.action.route"
          [queryParams]="item.data.action.queryParams"
        >
          <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
        </a>
      </ng-container>
      <ng-container *ngIf="item.data.action?.href">
        <a
          class="list-group-item d-flex align-items-center list-group-item-action"
          [omTrackLink]="item.data.action.trackingFunction"
          [href]="item.path"
        >
          <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
        </a>
      </ng-container>
      <ng-container *ngIf="!item.data.action?.route && !item.data.action?.href && item.data.action?.label">
        <button
          class="list-group-item d-flex align-items-center list-group-item-action"
          (click)="item.buttonClick.emit($event)"
        >
          <ng-container *ngTemplateOutlet="content; context: { $implicit: item }"></ng-container>
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #itemText let-item>
  <div class="flex-grow-1">
    <h6 class="m-0" data-cy="item-label">{{ item.label }}</h6>
    <span *ngIf="item.sublabel" [ngClass]="item.sublabelClass || bootstrapClass.Muted" data-cy="list-item-sublabel">
      {{ item.sublabel }}
    </span>
  </div>
</ng-template>

<ng-template #content let-item>
  <ng-container *ngTemplateOutlet="item.icon.templateRef"></ng-container>
  <ng-container *ngTemplateOutlet="itemText; context: { $implicit: item.data }"></ng-container>
</ng-template>
