import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

import { SendForgotPasswordEmail, SendForgotPasswordEmailVariables } from './__generated__/SendForgotPasswordEmail';

@Injectable()
export class SendForgotPasswordEmailGraphQL extends Mutation<
  SendForgotPasswordEmail,
  SendForgotPasswordEmailVariables
> {
  document = gql`
    mutation SendForgotPasswordEmail($email: String!, $usePreferredEmail: Boolean) {
      sendForgotPasswordEmail(input: { email: $email, usePreferredEmail: $usePreferredEmail }) {
        success
      }
    }
  `;
}
