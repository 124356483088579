import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LinksService } from '@app/core/links.service';
import { LoginService } from '@app/core/login.service';
import { ProgramHubComponent } from '@app/program/program-hub/program-hub.component';
import { InviteFriendsModalComponent } from '@app/shared/referrals/invite-friends-modal.component';
import { OmguiButtonSize, OmguiButtonVariant } from '@omgui/omgui-button/omgui-button.component';
import { Profile } from '@omgui/omgui-profile-bubble/omgui-profile-bubble.component';

import { User } from '../../../user';
import { PrincipalUser } from '../../__generated__/principal-user-graphql.service.types';
import { TargetUser } from '../../__generated__/valid-target-users-graphql.service.types';
import { NavbarAnalyticsService } from '../../navbar-analytics.service';

interface AccountSwitchingOption {
  user: PrincipalUser | TargetUser;
  role: string;
}

@Component({
  selector: 'om-navbar-dropdown',
  templateUrl: 'navbar-dropdown.component.html',
  styleUrls: ['navbar-dropdown.component.scss'],
})
export class NavbarDropdownComponent implements OnChanges, OnInit {
  @Input() profile: Profile;
  @Input() virtual: boolean;
  @Input() enrolledInMindsetPlus: boolean;
  @Input() inviteCta: string;
  @Input() canRegisterKid: boolean;
  @Input() directSignupEligible: boolean;

  @Input() currentUser: User;
  @Input() principalUser: PrincipalUser;
  @Input() targetUsers: TargetUser[] = [];

  @Output() principalUserClicked = new EventEmitter<void>();
  @Output() targetUserClicked = new EventEmitter<TargetUser>();

  accountSwitchingOptions: AccountSwitchingOption[];
  currentUserRole: string;

  readonly OmguiButtonVariant = OmguiButtonVariant;
  readonly OmguiButtonSize = OmguiButtonSize;

  constructor(
    private navbarAnalyticsService: NavbarAnalyticsService,
    public links: LinksService,
    private loginService: LoginService,
    private modal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.initCurrentUserData(this.currentUser);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currentUser) {
      this.initCurrentUserData(this.currentUser);
    }
  }

  selectTargetUser(targetUser: TargetUser) {
    if (targetUser.id === this.principalUser.id) {
      this.principalUserClicked.emit();
    } else {
      this.targetUserClicked.emit(targetUser);
    }
  }

  logout(e: MouseEvent) {
    e.preventDefault();
    this.loginService.logout();
  }

  openInviteModal() {
    this.navbarAnalyticsService.trackReferralModalOpen();
    this.modal.open(InviteFriendsModalComponent);
  }

  trackAccountClicked = () => this.navbarAnalyticsService.trackAccountClicked();
  trackAppointmentsClicked = () => this.navbarAnalyticsService.trackAppointmentsClicked();
  trackProviderClicked = () => this.navbarAnalyticsService.trackProviderClicked();
  trackRegisterChildLink = () => this.navbarAnalyticsService.trackRegisterChildLinkSelected();
  trackDirectSignupLink = () => this.navbarAnalyticsService.trackDirectSignupStarted();
  trackHelpCenterLink = () => this.navbarAnalyticsService.trackHelpCenterLink();
  trackMindsetSelected = () =>
    this.navbarAnalyticsService.trackMyProgramSelected({ programName: ProgramHubComponent.MINDSET_PROGRAM_TITLE });

  private initCurrentUserData(_user: User) {
    const currentUserId = this.currentUser && this.currentUser.id.toString();

    this.currentUserRole = this.principalUser && this.principalUser.id !== currentUserId ? 'Child' : 'Account Owner';

    this.accountSwitchingOptions = [];
    if (this.principalUser && this.principalUser.id !== currentUserId) {
      this.accountSwitchingOptions.push({
        user: this.principalUser,
        role: 'Account Owner',
      });
    }
    this.targetUsers.forEach(targetUser => {
      if (targetUser && targetUser.id !== currentUserId) {
        this.accountSwitchingOptions.push({
          user: targetUser,
          role: 'Child',
        });
      }
    });
  }
}
