import { Component, Input } from '@angular/core';

import { ProgramHubAnalyticsService } from '../program-hub/program-hub-analytics.service';
import { MESSAGE_MODULE, ProgramCareTeam } from '../program-types';

@Component({
  selector: 'om-care-team-sidebar',
  templateUrl: './care-team-sidebar.component.html',
})
export class CareTeamSidebarComponent {
  @Input() careGivers: ProgramCareTeam[];

  trackMessageClicked = this.programHubAnalyticsService.trackProgramModuleClicked.bind(
    this.programHubAnalyticsService,
    { module: MESSAGE_MODULE },
  );

  constructor(private programHubAnalyticsService: ProgramHubAnalyticsService) {}

  trackCareTeamRoleHover(role: string) {
    return this.programHubAnalyticsService.trackCareTeamRolesViewed({ role });
  }
}
