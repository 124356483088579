import { Component, Input } from '@angular/core';

import { PageState } from '@app/pipes/page-state/page-state';

import { ProgramHubAnalyticsService } from '../program-hub/program-hub-analytics.service';
import { ProgramImmediateCare } from '../program-types';

@Component({
  selector: 'om-program-immediate-care',
  templateUrl: './program-immediate-care.component.html',
  styleUrls: ['./program-immediate-care.component.scss'],
})
export class ProgramImmediateCareComponent {
  @Input() data: ProgramImmediateCare;

  pageState = PageState.SUCCESS;

  constructor(private programHubAnalyticsService: ProgramHubAnalyticsService) {}
  trackImmediateCareClicked() {
    return this.programHubAnalyticsService.trackProgramModuleClicked.bind(this.programHubAnalyticsService, {
      module: this.data?.moduleName,
    });
  }
}
