import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';
import { LinksService } from '@app/core/links.service';
import { MembershipService } from '@app/core/membership.service';
import { HEADER_BANNER } from '@app/core/mixpanel.constants';
import { UserService } from '@app/core/user.service';

@Component({
  selector: 'om-direct-signup-banner',
  templateUrl: './full-width-centered-banner.component.html',
  styleUrls: ['./full-width-centered-banner.component.scss'],
})
export class DirectSignupBannerComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    private membershipService: MembershipService,
    private userService: UserService,
    private linksService: LinksService,
  ) {}

  text: string;
  cta = 'Get Started';
  icon: null;
  linkTo = this.linksService.referrals;

  ngOnInit() {
    combineLatest([this.userService.user$, this.membershipService.membership$])
      .pipe(take(1))
      .subscribe(([user, membership]) => {
        this.text = 'Register your spouse or dependents';
        if (
          user.whitelistedEmployee &&
          !user.hasUnregisteredDependents &&
          membership.enterprisePedsRegistrationEnabled
        ) {
          this.text = 'Register dependents';
        }
      });

    this.userService.getUser();
    this.membershipService.getMembership();
  }

  ctaAction() {
    this.analyticsService.trackDirectSignupStarted(HEADER_BANNER);
  }
}
