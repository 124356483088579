import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { NgbCollapse, NgbCollapseModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import cloneDeep from 'lodash-es/cloneDeep';

import { OmguiListCardItem, OmguiListCardModule } from '@omgui/omgui-list-card/omgui-list-card.component';

import { OmguiCollapseStateIconModule } from '../omgui-collapse-state-icon/omgui-collapse-state-icon.component';

export interface CompletionAccordionItem {
  completed: boolean;
  listCardData: OmguiListCardItem;
}

@Component({
  selector: 'omgui-completion-accordion',
  templateUrl: './omgui-completion-accordion.component.html',
  styleUrls: ['./omgui-completion-accordion.component.scss'],
})
export class OmguiCompletionAccordionComponent implements OnInit {
  /** @ignore */
  @ViewChild('collapse') collapse: NgbCollapse;

  /** The items that show in the expanded accordion. Sorted by completed first. */
  @Input() set items(items: CompletionAccordionItem[]) {
    this._items = cloneDeep(items).sort((a, b) => (a.completed === b.completed ? 0 : a.completed ? -1 : 1));
    this.uncompletedItemsCount = this.items.filter(item => !item.completed).length;
    this.singularOrPluralItems = this.uncompletedItemsCount === 1 ? 'item' : 'items';
    this.completionPercentage = Math.round(
      ((this.items.length - this.uncompletedItemsCount) / this.items.length) * 100,
    );
  }

  /** The title of the completion accordion */
  @Input() title: string;

  /** The subtitle of the completion accordion */
  @Input() subtitle: string;

  /** Option to set initial state of collapsed */
  @Input() expandOnInit = false;

  /** @ignore */
  completionPercentage: number;

  /** @ignore */
  uncompletedItemsCount: number;

  /** @ignore */
  singularOrPluralItems: string;

  /** @ignore */
  isExpanded = false;

  /** @ignore */
  isExpanding = false;

  /** @ignore */
  shouldRoundBottom = true;

  /** @ignore */
  private isCollapsed = true;

  /** @ignore */
  private isCollapsing = false;

  /** @ignore */
  chevronPointsUp = false;

  /** @ignore */
  private _items: CompletionAccordionItem[];

  get items(): CompletionAccordionItem[] {
    return this._items;
  }

  onCollapseFinished(): void {
    this.isCollapsed = true;
    this.isExpanded = false;

    this.isExpanding = false;
    this.isCollapsing = false;

    this.updateUiControls();
  }

  onExpandFinished(): void {
    this.isCollapsed = false;
    this.isExpanded = true;

    this.isExpanding = false;
    this.isCollapsing = false;

    this.updateUiControls();
  }

  onClick(): void {
    this.isExpanding = this.isCollapsed;
    this.isCollapsing = !this.isCollapsed;

    this.updateUiControls();
    this.collapse.toggle();
  }

  private updateUiControls() {
    this.chevronPointsUp = this.isExpanding || (this.isExpanded && !this.isCollapsing);
    this.shouldRoundBottom = this.isCollapsed && !this.isExpanded && !this.isExpanding && !this.isCollapsing;
  }

  ngOnInit(): void {
    this.expandOnInit && this.onExpandFinished();
  }
}

@NgModule({
  declarations: [OmguiCompletionAccordionComponent],
  imports: [CommonModule, NgbCollapseModule, NgbProgressbarModule, OmguiListCardModule, OmguiCollapseStateIconModule],
  exports: [OmguiCompletionAccordionComponent],
})
export class OmguiCompletionAccordionModule {}
