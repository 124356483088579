<select
  [class]="'form-control service-area-select ' + additionalClasses"
  name="service-area"
  data-cy="service-area"
  required="true"
  aria-label="Select Service Area"
  [ngModel]="defaultSelection"
  (ngModelChange)="setSelectedServiceArea($event)"
>
  <option *ngFor="let serviceArea of (serviceAreas$ | async)" [ngValue]="serviceArea"> {{ serviceArea.name }} </option>
</select>
