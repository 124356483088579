import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';
import { DocumentSignerGraphQLService } from '@app/core/document-signer-graphql.service';
import { DocumentSigner } from '@app/core/legal-documents';
import { CurrentUserIdGraphQLService } from '@app/core/tos/current-user-id-graphql.service';
import { BeneficiaryForLegalDoc } from '@app/shared';

@Component({
  selector: 'om-tos',
  templateUrl: './tos-interstitial.component.html',
})
export class TosInterstitialComponent implements OnInit {
  documentSignerAndBeneficiary$: Observable<[DocumentSigner, BeneficiaryForLegalDoc]>;

  constructor(
    private attemptedPathService: AttemptedPathService,
    private documentSignerGraphQLService: DocumentSignerGraphQLService,
    private currentUserIdGraphQLService: CurrentUserIdGraphQLService,
  ) {}

  ngOnInit() {
    const documentSigner$ = this.documentSignerGraphQLService
      .fetch()
      .pipe(map(result => result.data.patient.documentSigner));
    const beneficiary$ = this.currentUserIdGraphQLService
      .fetch()
      .pipe(map(result => ({ id: +result.data.patient.id })));

    this.documentSignerAndBeneficiary$ = combineLatest([documentSigner$, beneficiary$]);
  }

  continue() {
    this.attemptedPathService.navigateToAttemptedPath();
  }
}
