import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { DocumentSigner, LegalDocumentsService, TermsOfService } from '@app/core/legal-documents';
import { FlashService, MessageType } from '@app/shared/flash.service';

import { User } from '../user';

export interface BeneficiaryForLegalDoc extends Pick<User, 'id'> {}

@Component({
  selector: 'om-legal-doc',
  templateUrl: './legal-doc.component.html',
  styleUrls: ['./legal-doc.component.scss'],
})
export class LegalDocComponent implements OnInit {
  @Input() set doc(doc: TermsOfService) {
    this._doc = doc;
    this.trustedText = this.domSanitizer.bypassSecurityTrustHtml(doc.text);
  }

  @Input() beneficiary: BeneficiaryForLegalDoc;
  @Input() signer: DocumentSigner;
  @Input() beneficiaryLoggedIn: boolean;
  @Input() signOnBehalf: boolean;
  @Input() enabled: boolean;
  @Output() docSigned = new EventEmitter<TermsOfService>();

  private _doc: TermsOfService;

  trustedText: SafeHtml;
  collapsed = true;
  signed = false;
  processing = false;

  constructor(
    private legalDocumentsService: LegalDocumentsService,
    public flashService: FlashService,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.signed = this.doc.signedOrSignedOnBehalf;
  }

  get doc() {
    return this._doc;
  }

  toggleDoc($event: KeyboardEvent | MouseEvent) {
    $event.preventDefault();
    this.collapsed = !this.collapsed;
  }

  signDoc($event: MouseEvent) {
    $event.preventDefault();
    if (this.signed || this.processing) {
      return;
    } else {
      this.processing = true;

      this.sign();
    }
  }

  private sign() {
    let submitSignature;
    if (this.signOnBehalf) {
      if (this.beneficiaryLoggedIn) {
        submitSignature = this.legalDocumentsService.signForBeneficiaryWhenLoggedInAsBeneficiary(
          this.doc,
          this.beneficiary,
          this.signer,
        );
      } else {
        submitSignature = this.legalDocumentsService.signForBeneficiaryWhenLoggedInAsAdministrator(
          this.doc,
          this.beneficiary,
        );
      }
    } else {
      submitSignature = this.legalDocumentsService.signForSelf(this.doc);
    }

    submitSignature.subscribe(data => this.handleResponse(data));
  }

  handleResponse = (data: { success: boolean; error?: string; errors?: string[] }) => {
    if (data.error || data.errors) {
      this.flashService.addFlashMessage(
        "We've encountered an issue submitting your request. Please log out and try again.",
        MessageType.ERROR,
      );
      this.processing = false;
    } else {
      this.signed = true;
      this.processing = false;
      this.docSigned.emit(this.doc);
    }
  };
}
