<om-card-list class="program-appointments">
  <ng-template om-card-list-row>
    <omgui-summary-row>
      <ng-template omgui-summary-row-icon>
        <div class="appointment-icon rounded-circle d-inline-flex justify-content-center p-2">
          <img role="presentation" src="/assets/images/icons/icn_appointment.svg" />
        </div>
      </ng-template>
      <ng-template omgui-summary-row-primary-text>Your appointments (upcoming and past)</ng-template>
      <omgui-action
        ><button
          routerLink="/appointments/list"
          class="btn btn-link"
          (click)="trackAppointmentsLinkClicked()"
          data-cy="program-appointments-action"
          >View All</button
        ></omgui-action
      >
    </omgui-summary-row>
  </ng-template>
</om-card-list>
