<div>
  <div class="doc-title p-3 d-flex justify-content-between">
    <span class="agreement-title"><ng-content></ng-content></span>
    <button
      (click)="agreeSelected($event)"
      class="checkbox-lookalike"
      [disabled]="agree"
      [class.checked]="agree"
      data-cy="sign-legal-agreement"
    >
      <span class="checkmark" *ngIf="!processing" [class.disabled]="agree"></span>
      <om-logo-spinner class="spinner" *ngIf="processing" size="28" color="#068466"></om-logo-spinner>
    </button>
  </div>
</div>
