import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Dict } from 'mixpanel-browser';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { State } from '@app/app.reducer';
import { AnalyticsService } from '@app/core/analytics.service';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';
import {
  FLOW_ACCOUNT_UPDATE,
  MODULE_SETTINGS_PAGE,
  MP_EVENT_ADD_PREFERRED_EMAIL_ACTION,
  MP_EVENT_EDIT_EMAIL,
  MP_EVENT_EDIT_EMAIL_ACTION,
  MP_EVENT_EMAIL_VERIFICATION_SENT,
  MP_EVENT_PAGE_VIEWED,
} from '@app/core/mixpanel.constants';
import { MixpanelService } from '@app/core/mixpanel.service';
import { EmailVerificationAnalyticsHelperService } from '@app/membership/email-verification-analytics-helper.service';

export enum EmailType {
  preferred = 'Preferred Email',
  login = 'Login Email',
}

enum EmailEditActions {
  save = 'Save',
  cancel = 'Cancel',
  delete = 'Delete',
}

@Injectable({
  providedIn: 'root',
})
export class MembershipAnalyticsService extends AnalyticsService {
  private readonly settingsFlowAndModule = {
    flow: FLOW_ACCOUNT_UPDATE,
    module: MODULE_SETTINGS_PAGE,
  };

  constructor(
    mixpanel: MixpanelService,
    store: Store<State>,
    private emailHelper: EmailVerificationAnalyticsHelperService,
    launchDarkly: LaunchDarklyService,
  ) {
    super(mixpanel, store, launchDarkly);
  }

  renewScreenViewed() {
    return this.trackWithDefaultProperties('Renew Screen Viewed');
  }

  renewScreenPayAmf() {
    return this.trackWithDefaultProperties('Renew Screen Pay AMF Tapped');
  }

  renewScreenActivateSponsoredBenefit() {
    return this.trackWithDefaultProperties('Activate Sponsored Benefit Link Tapped');
  }

  settingsUpdateActivation() {
    return this.trackWithDefaultProperties('Update Activation Code Clicked');
  }

  settingsCancelMembership() {
    return this.trackWithDefaultProperties('Membership Cancellation Started', this.settingsFlowAndModule);
  }

  settingsCancelAutoRenewal() {
    return this.trackWithDefaultProperties('Cancel Auto Renewal Clicked');
  }

  settingsReactivateMembership() {
    return this.trackWithDefaultProperties('Reactivate Membership Clicked');
  }

  settingsConvertToSponsored() {
    return this.trackWithDefaultProperties(
      'Activate Employer Sponsored Membership Clicked',
      this.settingsFlowAndModule,
    );
  }

  settingsConvertTrialMembership() {
    return this.trackWithDefaultProperties('Trial to Consumer Conversion Started', this.settingsFlowAndModule);
  }

  settingsConvertToPersonal() {
    return this.trackWithDefaultProperties('Convert to Personal Membership Clicked', this.settingsFlowAndModule);
  }

  settingsMembershipReactivated() {
    return this.trackWithDefaultProperties('Membership Reactivated');
  }

  trackSettingsPageViewed(source?: string) {
    this.getEmailVerificationProperties().subscribe({
      next: properties => {
        if (source) {
          properties.source = source;
        }
        properties.module = 'Membership and Billing Page';

        this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, properties);
      },
    });
  }

  billingInfoUpdateStarted() {
    return this.trackWithDefaultProperties('Update Billing Info', this.settingsFlowAndModule);
  }

  billingInfoUpdated() {
    return this.trackWithDefaultProperties('Billing Info Updated', this.settingsFlowAndModule);
  }

  expiredMembershipBannerClicked() {
    return this.trackWithDefaultProperties('Update Expired Membership Banner Clicked', {
      flow: 'Renew Membership',
    });
  }

  reactivateMembershipBannerClicked() {
    return this.trackWithDefaultProperties('Update Reactivate Membership Banner Clicked', {
      flow: 'Reactivate Membership',
    });
  }

  expiringB2bMembershipBannerClicked() {
    return this.trackWithDefaultProperties('Update Expiring Membership Banner Clicked', {
      flow: FLOW_ACCOUNT_UPDATE,
    });
  }

  invalidCCMembershipBannerClicked() {
    return this.trackWithDefaultProperties('Invalid Credit Card Banner Clicked', {
      flow: FLOW_ACCOUNT_UPDATE,
    });
  }

  giftCardRedeemed() {
    return this.trackWithDefaultProperties(MP_EVENT_PAGE_VIEWED, {
      flow: 'Post Gift Registration',
      module: MODULE_SETTINGS_PAGE,
      submodule: 'Gift Membership Extension Modal',
    });
  }

  activateEmployerSponsoredMembership() {
    return this.trackWithDefaultProperties('Activate Employer Sponsored Membership Clicked', {
      flow: 'Renew Membership',
      module: 'Renew Page',
      submodule: null,
    });
  }

  convertToPersonalMembership() {
    return this.trackWithDefaultProperties('Convert to Personal Membership Clicked', {
      flow: 'Renew Membership',
      module: 'Renew Page',
      submodule: null,
    });
  }

  requestMedicalRecords() {
    return this.trackWithDefaultProperties('Request Medical Records Release Clicked', {
      flow: 'Renew Membership',
      module: 'Renew Page',
      submodule: null,
    });
  }

  renewConsumerMemberBillingInfo() {
    return this.trackWithDefaultProperties('Billing Info Updated', {
      flow: 'Renew Membership',
      module: 'Renew Page',
      submodule: null,
    });
  }

  trackVerificationEmailSent(email_type: EmailType) {
    this.getEmailVerificationProperties().subscribe({
      next: properties =>
        this.trackWithDefaultProperties(MP_EVENT_EMAIL_VERIFICATION_SENT, {
          ...properties,
          email_type,
        }),
    });
  }

  trackEmailEditSaved(emailType: EmailType) {
    this.trackEmailAction(EmailEditActions.save, emailType);
  }

  trackEmailEditCanceled(emailType: EmailType) {
    this.trackEmailAction(EmailEditActions.cancel, emailType);
  }

  trackEmailDeleted(emailType: EmailType) {
    this.trackEmailAction(EmailEditActions.delete, emailType);
  }

  trackEditEmailClicked(email_type: EmailType) {
    this.getEmailVerificationProperties().subscribe({
      next: properties =>
        this.trackWithDefaultProperties(MP_EVENT_EDIT_EMAIL, {
          ...properties,
          email_type,
        }),
    });
  }

  trackAddPreferredEmailCanceled() {
    this.trackAddPreferredEmailAction(EmailEditActions.cancel);
  }

  private trackEmailAction(action: EmailEditActions, email_type: EmailType) {
    this.getEmailVerificationProperties().subscribe(properties => {
      this.trackWithDefaultProperties(MP_EVENT_EDIT_EMAIL_ACTION, {
        ...properties,
        action_selected: action,
        email_type,
      });
    });
  }

  private getEmailVerificationProperties(): Observable<Dict> {
    const { emailStatuses, membershipType } = this.emailHelper;
    return combineLatest([emailStatuses, membershipType]).pipe(
      map(([statuses, om_membership_type]) => ({
        ...this.settingsFlowAndModule,
        ...statuses,
        om_membership_type,
      })),
    );
  }

  private trackAddPreferredEmailAction(action: EmailEditActions.save | EmailEditActions.cancel) {
    this.emailHelper.membershipType.subscribe(om_membership_type => {
      this.trackWithDefaultProperties(MP_EVENT_ADD_PREFERRED_EMAIL_ACTION, {
        ...this.settingsFlowAndModule,
        om_membership_type,
        action_selected: action,
        email_type: EmailType.preferred,
      });
    });
  }
}
