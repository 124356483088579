import { Injectable } from '@angular/core';
import { Mutation, gql } from 'apollo-angular';

export interface SignDocVariables {
  type: string;
}

/**
 * GraphQL mutation that signs a legal document ({TermsOfService}).
 */

@Injectable()
export class SignLegalDocGraphQL extends Mutation<{}, SignDocVariables> {
  document = gql`
    mutation SignLegalDocument($type: String!) {
      sign_legal_document(input: { type: $type }) {
        legal_document {
          type
          signed
        }
      }
    }
  `;
}
