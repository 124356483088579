import { CommonModule } from '@angular/common';
import { Component, ContentChild, Directive, Input, NgModule, TemplateRef } from '@angular/core';
import { Params, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';

import { TrackLinkModule } from '@app/utils/track-link.directive';

@Directive({
  selector: '[omgui-display-card-icon]',
})
export class OmguiDisplayCardIconDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'omgui-display-card',
  templateUrl: './omgui-display-card.component.html',
  styleUrls: ['./omgui-display-card.component.scss'],
})
export class OmguiDisplayCardComponent {
  @ContentChild(OmguiDisplayCardIconDirective) icon: OmguiDisplayCardIconDirective;

  /** The label text. */
  @Input() label: string;

  /** If this card is a link, the value passed to the routerLink */
  @Input() link: string;

  /** Optional function that is executed before the linked resource is navigated to. Meant to be assigned an analytics tracking function */
  @Input() trackLink?: () => Observable<unknown> | undefined;

  /** Query params will be passed to the routerLink */
  @Input() queryParams: Params;
}

@NgModule({
  declarations: [OmguiDisplayCardComponent, OmguiDisplayCardIconDirective],
  imports: [CommonModule, RouterModule, TrackLinkModule],
  exports: [OmguiDisplayCardComponent, OmguiDisplayCardIconDirective],
})
export class OmguiDisplayCardModule {}
