import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TermsOfService } from '@app/core/legal-documents';

@Component({
  selector: 'om-legal-agreement',
  templateUrl: './legal-agreement.component.html',
  styleUrls: ['./legal-agreement.component.scss'],
})
export class LegalAgreementComponent {
  @Input() processing: boolean;
  @Input() agree: boolean;
  @Output() agreed = new EventEmitter<TermsOfService>();

  agreeSelected($event: MouseEvent) {
    $event.preventDefault();
    this.agreed.emit();
  }
}
