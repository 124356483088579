import { Injectable } from '@angular/core';
import { Query, gql } from 'apollo-angular';

import { MembershipResult } from './__generated__/MembershipResult';

@Injectable()
export class MembershipGraphQL extends Query<MembershipResult> {
  document = gql`
    query MembershipResult {
      membership {
        id
        planId
        planType
        plan {
          id
          ... on B2bPlan {
            isCore
            company {
              name
              includesDependent
              verifyDependentInfo
              enterprisePedsRegistrationEnabled
            }
            hasActiveDiscountTypes
          }
          ... on StripePlan {
            amount
            amountInDollars
          }
        }
      }
    }
  `;
}
