import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics.service';
import { ConfigService } from '@app/core/config.service';
import { MYONE_HEADER_BANNER, REFERRAL_MODAL } from '@app/core/mixpanel.constants';
import { UserService } from '@app/core/user.service';
import { InviteFriendsModalComponent } from '@app/shared/referrals/invite-friends-modal.component';
import { User } from '@app/shared/user';

@Component({
  selector: 'om-referral-banner',
  templateUrl: './full-width-centered-banner.component.html',
  styleUrls: ['./full-width-centered-banner.component.scss'],
})
export class ReferralBannerComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private analyticsService: AnalyticsService,
    private config: ConfigService,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
  ) {}

  user: User;
  text: string;
  cta: string;
  icon = true;
  linkTo: string[];

  private destroy$ = new Subject();
  private bannerShownMixpanelProperties: object = {
    promotion_code_amount: null,
    promotion_code_percent: null,
  };

  ngOnInit() {
    this.userService.getUser();
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe((user: User) => {
      this.user = user;

      this.text = 'Give the gift of good health';
      if (this.user.referralDiscount) {
        this.text = `Give ${this.user.referralDiscount.getDiscountString()} off One Medical`;

        this.bannerShownMixpanelProperties = {
          promotion_code_amount: this.user.referralDiscount.getDiscountDollarAmount(),
          promotion_code_percent: this.user.referralDiscount.getDiscountPercentAmount(),
        };
      }
    });
    this.cta = 'Invite Friends';
    this.autoShowReferralModal();
  }

  ngAfterViewInit() {
    this.analyticsService.trackReferralBannerShown(this.bannerShownMixpanelProperties);
  }

  ctaAction() {
    this.analyticsService.trackReferralModalOpen(REFERRAL_MODAL, MYONE_HEADER_BANNER);
    this.modal.open(InviteFriendsModalComponent);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private autoShowReferralModal() {
    const referralQuery = this.route.snapshot.queryParams.show_referral_modal === 'true';

    if (referralQuery) {
      this.ctaAction();
    }
  }
}
