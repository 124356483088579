import { SafeHtml } from '@angular/platform-browser';

import { Provider } from '@app/shared/provider';
import { ContentConverter } from '@app/shared/task';

export class LabOrderContent {
  fastingRequired: boolean;
  numberOfTests: number;
  testsOrdered: string[];
  appointmentNeeded: boolean;
}

export class VisitFollowUpOrderContent {
  visitReason: string;
  visitWithProvider: Provider;
  appointmentType: string;
  appointmentTypeId: number;
}

export class BasicFollowUpOrderContent {
  problem: string;
  feedbackOptions: string[];
  feedback: string;
}

export class GenericFollowUpOrderContent {
  question: string;
  message: string;
  title: string;
}

export class VaccineOrderContent {
  isAutomated: boolean;
  name: string;
  step: string;
  patientEduText: SafeHtml;
  walkin: boolean;
  isSeries: boolean;
  isFluVaccine: boolean;
  instruction: string;
  title: string;
  appointmentTypeId: number;
}

export class SurveyOrderContent {
  surveyId?: number;
  instructions: string;
}

export class ConsultOrderContent {
  providerImageUrl: string;
  providerName: string;
  consultantDisplayName: string;
  consultantPhoneNumber: string;
  consultantSpecialtyName: string;
  consultantFacilityName: string;
  consultantAddress1: string;
  consultantAddress2: string;
  consultantCity: string;
  consultantState: string;
  consultantZip: string;
  consultantLatitude: string;
  consultantLongitude: string;
}

export class ProcedureOrderContent {
  providerImageUrl: string;
  providerName: string;
  consultantDisplayName: string;
  consultantPhoneNumber: string;
  consultantSpecialtyName: string;
  consultantFacilityName: string;
  consultantAddress1: string;
  consultantAddress2: string;
  consultantCity: string;
  consultantState: string;
  consultantZip: string;
  consultantLatitude: string;
  consultantLongitude: string;
}

export type OrderContent =
  | LabOrderContent
  | VisitFollowUpOrderContent
  | BasicFollowUpOrderContent
  | GenericFollowUpOrderContent
  | VaccineOrderContent
  | SurveyOrderContent
  | ConsultOrderContent
  | ProcedureOrderContent;

export class BuildContentFromTaskGraphQL {
  order: Record<string, any>;
  markdownService: ContentConverter;

  constructor(order: Record<string, any>, markdownService: ContentConverter) {
    this.order = order;
    this.markdownService = markdownService;
  }

  build(): Partial<OrderContent> | null {
    switch (this.order.orderType) {
      case 'LabOrder':
        return this.buildLabOrderContent();
      case 'VisitFollowUpOrder':
        return this.buildVisitFollowUpOrderContent();
      case 'BasicFollowUpOrder':
        return this.buildBasicFollowUpOrderContent();
      case 'VaccineOrder':
        return this.buildVaccineOrderContent();
      case 'SurveyOrder':
        return this.buildSurveyOrderContent();
      case 'GenericFollowUpOrder':
        return this.buildGenericFollowUpOrderContent();
      case 'ConsultOrder':
        return this.buildConsultOrderContent();
      case 'ProcedureOrder':
        return this.buildProcedureOrderContent();
      default:
        return null;
    }
  }

  buildLabOrderContent() {
    const content = new LabOrderContent();
    content.fastingRequired = this.order.labOrder.fasting;
    content.testsOrdered = this.order.labOrder.testsOrdered;
    content.numberOfTests = this.order.labOrder.testsOrdered.length;
    content.appointmentNeeded = this.order.labOrder.appointmentNeeded;
    return content;
  }

  buildVisitFollowUpOrderContent() {
    const content = new VisitFollowUpOrderContent();
    content.visitReason = this.order.visitFollowUpOrder.reasonDetails;
    content.visitWithProvider = this.order.visitFollowUpOrder.visitWithProvider;
    content.appointmentType = this.order.visitFollowUpOrder.appointmentType;
    content.appointmentTypeId = this.order.visitFollowUpOrder.appointmentType?.id;
    return content;
  }

  buildBasicFollowUpOrderContent() {
    const content = new BasicFollowUpOrderContent();
    content.problem = this.order.basicFollowUpOrder.problem?.layDescription;
    content.feedback = this.order.basicFollowUpOrder.patientFeedback;
    content.feedbackOptions = ['Worse', 'Same', 'Better', 'Great'];
    return content;
  }

  buildVaccineOrderContent() {
    const content = new VaccineOrderContent();
    content.isAutomated = this.order.vaccineOrder.isAutomated;
    content.walkin = this.order.vaccineOrder.isWalkin;
    content.isSeries = this.order.vaccineOrder.isSeries;
    content.name = this.order.vaccineOrder.vaccineName;
    content.step = this.order.vaccineOrder.step;
    content.patientEduText = this.markdownService.convert(this.order.vaccineOrder.vaccine.patientEduText);
    content.isFluVaccine = this.order.vaccineOrder.vaccine.isFluVaccine;
    content.appointmentTypeId = this.order.appointmentType?.id;
    return content;
  }

  buildSurveyOrderContent() {
    const content = new SurveyOrderContent();
    content.surveyId = this.order.surveyOrder.survey.id;
    content.instructions = this.order.surveyOrder.survey.instructions;
    return content;
  }

  buildGenericFollowUpOrderContent() {
    const content = new GenericFollowUpOrderContent();
    content.question = this.order.genericFollowUpOrder.question;
    return content;
  }

  buildConsultOrderContent() {
    const content = new ConsultOrderContent();

    content.providerImageUrl = this.order.provider.profileImageUrl;
    content.providerName = this.order.provider.displayName;
    content.consultantSpecialtyName = this.order.consultOrder?.specialty?.name;

    content.consultantDisplayName = this.order.consultant.displayName;
    content.consultantPhoneNumber = this.order.consultant.phoneNumber;
    content.consultantFacilityName = this.order.consultant.facilityName;

    content.consultantAddress1 = this.order.consultant.address?.address1;
    content.consultantAddress2 = this.order.consultant.address?.address2;
    content.consultantCity = this.order.consultant.address?.city;
    content.consultantState = this.order.consultant.address?.state;
    content.consultantZip = this.order.consultant.address?.zip;
    content.consultantLatitude = this.order.consultant.address?.latitude;
    content.consultantLongitude = this.order.consultant.address?.longitude;

    return content;
  }

  buildProcedureOrderContent() {
    const content = new ProcedureOrderContent();

    content.providerName = this.order.provider.displayName;
    content.providerImageUrl = this.order.provider.profileImageUrl;

    content.consultantDisplayName = this.order.consultant.displayName;
    content.consultantDisplayName = this.order.consultant.displayName;
    content.consultantPhoneNumber = this.order.consultant.phoneNumber;
    content.consultantFacilityName = this.order.consultant.facilityName;

    content.consultantAddress1 = this.order.consultant.address?.address1;
    content.consultantAddress2 = this.order.consultant.address?.address2;
    content.consultantCity = this.order.consultant.address?.city;
    content.consultantState = this.order.consultant.address?.state;
    content.consultantZip = this.order.consultant.address?.zip;
    content.consultantLatitude = this.order.consultant.address?.latitude;
    content.consultantLongitude = this.order.consultant.address?.longitude;

    return content;
  }
}
