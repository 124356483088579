<div [formGroup]="passwordForm">
  <div
    class="text-field d-flex flex-row align-items-center"
    [class.focused]="inputFocused"
    [class.ng-invalid]="passwordForm.invalid"
    [class.ng-dirty]="passwordForm.dirty && passwordForm.touched"
  >
    <input
      class="hidden flex-grow-1 form-control"
      #input
      [type]="showPassword ? 'text' : 'password'"
      id="password"
      name="password"
      required="true"
      aria-describedby="password-error"
      formControlName="password"
      placeholder="Enter password"
      (focus)="focusContainingDiv()"
      (blur)="blurContainingDiv()"
    />
    <div class="show-hide-password show-password p-2" (click)="showPassword = !showPassword">
      <om-show-password aria-hidden="true" class="show-password"></om-show-password>
      {{ showPassword ? 'Hide' : 'Show' }}
    </div>
  </div>

  <div class="form-group row mt-2 password-validations">
    <div class="col-12 col-sm-6" *ngFor="let error of passwordComplexityErrorsToMessages">
      <span class="bullet" [class.d-none]="!hasErrors(error.key)">•</span>
      <om-svg aria-hidden="true" [class.d-none]="!noError(error.key)" image="checkmark"></om-svg>
      <!-- todo: refactor to use <om-svg-checkmark> component -->
      {{ error.message }}
    </div>
  </div>

  <span *ngIf="serverError" class="error-message" id="password-error"> {{ serverError }} </span>
</div>
