import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable()
export class AdultOnlyGuardService implements CanActivate {
  constructor(private authService: AuthService, private userService: UserService, private router: Router) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.authService.initialized$.pipe(
      take(1),
      switchMap(() => {
        if (this.authService.isLoggedIn()) {
          this.userService.getUser();

          return this.userService.user$.pipe(
            take(1),
            map(user => {
              if (user.isPediatric()) {
                return this.router.parseUrl('/');
              } else {
                return true;
              }
            }),
          );
        } else {
          return observableOf(true);
        }
      }),
    );
  }
}
