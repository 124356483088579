import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import isEqual from 'lodash-es/isEqual';
import Rollbar from 'rollbar';
import { from as observableFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { LinksService } from '@app/core/links.service';
import { RollbarService } from '@app/core/rollbar.service';
import { ConsumerRegistrationActions } from '@app/registration/consumer/action-types';

import { ConfigService } from '../../core/config.service';
import { Coupon } from '../coupon';
import { StripeClient } from './stripe-client';

import StripePaymentRequest = stripe.paymentRequest.StripePaymentRequest;
import StripePaymentRequestUpdateOptions = stripe.paymentRequest.StripePaymentRequestUpdateOptions;

export interface StripePaymentIntentResponse {
  id?: stripe.paymentIntents.PaymentIntent['id'];
  amount: stripe.paymentIntents.PaymentIntent['amount'];
  client_secret: stripe.paymentIntents.PaymentIntent['client_secret'];
  status?: stripe.paymentIntents.PaymentIntent['status'];
}

export interface StripePaymentIntentAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
}

export interface StripePaymentIntentBillingDetails {
  email?: string;
  name: string;
  address: StripePaymentIntentAddress;
}

export interface RegisterPaymentInfoPayload {
  paymentMethodId: string;
  discountCode?: string;
}

enum StripePaymentIntentStatus {
  requires_payment_method = 'requires_payment_method',
  requires_action = 'requires_action',
  succeeded = 'succeeded',
}

@Injectable()
export class StripeService {
  private discountCode: string;
  private paymentRequestCoupon: Coupon;
  private shippingAddress: StripePaymentIntentBillingDetails;
  private stripePaymentIntentResponse: StripePaymentIntentResponse;
  private stripePaymentRequest: StripePaymentRequest;
  private stripeClient: StripeClient;
  private get stripe() {
    return this.stripeClient.stripe;
  }

  private get elements() {
    return this.stripeClient.elements;
  }

  paymentRequestButtonApi: any;
  STRIPE_PAYMENT_INTENT_KEY = 'stripe_payment_intent';

  constructor(
    public configService: ConfigService,
    private apiService: ApiService,
    private linksService: LinksService,
    private store: Store,
    @Inject(RollbarService) private rollbar: Rollbar,
  ) {
    /* istanbul ignore next */
    this.stripeClient = new StripeClient(this.configService.json.stripePublishableKey);
  }

  completePayment(paymentMethodId: string, coupon?: Coupon) {
    const params: RegisterPaymentInfoPayload = { paymentMethodId };
    if (coupon) {
      params.discountCode = coupon.id;
    }
    this.store.dispatch(ConsumerRegistrationActions.registerPaymentInfo(params));
  }

  confirmCardSetup(clientSecret: string, element: stripe.elements.Element): Promise<stripe.SetupIntentResponse> {
    return this.stripe.confirmCardSetup(clientSecret, { payment_method: { card: element } });
  }

  createElement(type: stripe.elements.elementsType, options: stripe.elements.ElementsOptions): stripe.elements.Element {
    return this.elements.create(type, options);
  }

  createPaymentIntent(discountCode: string, shippingAddress: StripePaymentIntentBillingDetails): void {
    if (discountCode !== this.discountCode || !isEqual(this.shippingAddress, shippingAddress)) {
      this.discountCode = discountCode;
      this.shippingAddress = shippingAddress;
      const params = {
        payment_intent_id: this.stripePaymentIntentResponse?.id,
        discount_code: discountCode,
        shipping_address: shippingAddress,
      };

      if (this.isPaymentIntentSucceeded()) {
        // noop
      } else if (this.isPaymentIntentRequiresPaymentMethod()) {
        this.apiService
          .put(`/api/v2/patient/memberships/credit_card/browser_card_payment_intent_update`, params)
          .subscribe({
            next: this.onPaymentIntentCreateOrUpdate.bind(this),
            error: this.onPaymentIntentUpdateError.bind(this),
          });
      } else {
        this.apiService.post('/api/v2/patient/memberships/credit_card/browser_card_payment_intent', params).subscribe({
          next: this.onPaymentIntentCreateOrUpdate.bind(this),
          error: this.onPaymentIntentCreateError.bind(this),
        });
      }
    } else {
      if (this.stripePaymentIntentResponse?.client_secret) {
        this.store.dispatch(
          ConsumerRegistrationActions.stripePaymentIntentCreated({
            clientSecret: this.stripePaymentIntentResponse.client_secret,
          }),
        );
      }
    }
  }

  restorePaymentIntent(paymentIntentClientSecret: string): Observable<unknown> {
    return observableFrom(this.stripe.retrievePaymentIntent(paymentIntentClientSecret)).pipe(
      tap(({ paymentIntent, error }) => {
        if (paymentIntent) {
          this.stripePaymentIntentResponse = paymentIntent;
        } else {
          this.rollbar.error(`Stripe payment intent restoration error: ${JSON.stringify(error)}`);
        }
      }),
    );
  }

  createPaymentRequestButton(annualMembershipPrice: number, coupon: Coupon): void {
    this.paymentRequestCoupon = coupon ? { ...coupon } : null;
    // Note (6/17/2022): we had to update the label below once due to Apple requirements.
    // Should there be any future updates, let's make it customizeable (via LD, for example)
    const paymentRequest: stripe.paymentRequest.StripePaymentRequestOptions = {
      country: 'US',
      currency: 'usd',
      total: {
        label: 'One Medical annual membership fee',
        amount: this.getTotal(annualMembershipPrice, this.paymentRequestCoupon),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    };

    if (this.stripePaymentRequest) {
      /**
       * StripePaymentRequestUpdateOptions requires total and currency. The code currently does not provide a currency
       * value, and the casting was added to silence this TypeScript error.
       * */
      this.stripePaymentRequest.update({ total: paymentRequest.total } as StripePaymentRequestUpdateOptions);
    } else {
      this.stripePaymentRequest = this.stripe.paymentRequest(paymentRequest);
      this.paymentRequestButtonApi = this.elements.create('paymentRequestButton', {
        paymentRequest: this.stripePaymentRequest,
      });

      (async () => {
        // Check the availability of the Payment Request API first.
        const result = await this.stripePaymentRequest.canMakePayment();
        const isApplePayAvailable = result?.applePay || false;

        this.store.dispatch(ConsumerRegistrationActions.setApplePayAvailable({ isApplePayAvailable }));
      })();

      this.stripePaymentRequest.on('paymentmethod', ev => {
        this.completePayment(ev.paymentMethod.id, this.paymentRequestCoupon);
        ev.complete('success');
      });
    }
  }

  createToken(element: stripe.elements.Element): Promise<stripe.TokenResponse> {
    return this.stripe.createToken(element);
  }

  async continueWithAfterpay(billing_details: StripePaymentIntentBillingDetails) {
    const clientSecret = this.stripePaymentIntentResponse.client_secret;

    // @ts-ignore suppressing error for confirmAfterpayClearpayPayment not being available
    const { error } = await this.stripe.confirmAfterpayClearpayPayment(clientSecret, {
      payment_method: {
        billing_details: billing_details,
      },
      return_url: this.generateAfterpayReturnUrl(),
    });
    if (error) {
      const { message } = error;
      this.rollbar.error(`Stripe payment intent confirmation error: ${message}`);
      this.store.dispatch(ConsumerRegistrationActions.continueWithAfterpayFailure({ error: message }));
    }
  }

  generateAfterpayReturnUrl() {
    return this.linksService.processAfterpay + (this.discountCode ? `?discount_code=${this.discountCode}` : '');
  }

  getTotal(annualMembershipPrice: number, coupon: Coupon): number {
    let total = annualMembershipPrice;
    if (coupon) {
      if (coupon.amountOff) {
        total = total - coupon.amountOff / 100;
      } else if (coupon.percentOff) {
        total = total - total * (coupon.percentOff / 100);
      }
    }

    total = total % 1 === 0 ? Math.floor(total) : +total.toFixed(2);
    return total * 100;
  }

  isPaymentIntentRequiresPaymentMethod(): boolean {
    return this.stripePaymentIntentResponse?.status === StripePaymentIntentStatus.requires_payment_method;
  }

  isPaymentIntentSucceeded(): boolean {
    return this.stripePaymentIntentResponse?.status === StripePaymentIntentStatus.succeeded;
  }

  onPaymentIntentCreateError(error: HttpErrorResponse) {
    this.rollbar.error(`Stripe payment creation error: ${JSON.stringify(error)}`);
  }

  onPaymentIntentCreateOrUpdate(response: StripePaymentIntentResponse) {
    this.stripePaymentIntentResponse = response;
    this.store.dispatch(
      ConsumerRegistrationActions.stripePaymentIntentCreated({ clientSecret: response.client_secret }),
    );
  }

  onPaymentIntentUpdateError(error: HttpErrorResponse) {
    this.rollbar.error(`Stripe payment update error: ${JSON.stringify(error)}`);
  }
}
