import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'om-modal-template',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss'],
})
export class ModalTemplateComponent {
  @Input() heading: string;
  @Input() subheader: string;
  @Input() primaryCallToActionLabel: string;
  @Input() secondaryCallToActionLabel: string;
  @Input() primaryClasses = 'modal-footer pt-0 px-md-5 border-0 justify-content-center';
  @Input() showCloseButton = true;
  @Input() hidePrimaryCta = false;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() primaryCallToAction: EventEmitter<void> = new EventEmitter<void>();
  @Output() secondaryCallToAction: EventEmitter<void> = new EventEmitter<void>();
}
