import { Injectable } from '@angular/core';
import { createFeatureSelector, createSelector, select, Store } from '@ngrx/store';
import { defer, from, iif, of as observableOf, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { FeatureFlagActions } from '@app/core/feature-flags/feature-flag.actions';
import { FeatureFlagState } from '@app/core/feature-flags/feature-flag.reducer';
import { LaunchDarklyService } from '@app/core/feature-flags/launchdarkly.service';

export const selectFeatureFlagState = createFeatureSelector<FeatureFlagState>('featureFlags');

export const getFeatureFlag = (props: { flag: string }) =>
  createSelector(selectFeatureFlagState, (state: FeatureFlagState) => state.flags[props.flag]);

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagSelectors {
  constructor(
    private store: Store<FeatureFlagState>,
    private featureFlagActions: FeatureFlagActions,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  getFeatureFlag<T>(flag: string, defaultValue?: T): Observable<T> {
    return this.store.pipe(
      select(getFeatureFlag({ flag })),
      tap(flagValue => {
        if (flagValue === undefined) {
          this.featureFlagActions.loadFeatureFlag(flag, defaultValue);
        }
      }),
      filter(flagValue => flagValue !== undefined),
    );
  }

  getMixpanelConfiguredFeatureFlag(flag: string, defaultValue?: boolean): Observable<boolean> {
    return this.getFeatureFlag<boolean | number>(flag, defaultValue).pipe(
      map(flagValue => {
        if (flagValue === true || flagValue === false) {
          return flagValue;
        }

        return flagValue > 0;
      }),
    );
  }

  getFeatureFlagForAnonymousUser<T>(
    flag: string,
    defaultValue?: T,
    isMixpanelConfigured = false,
  ): Observable<T | boolean> {
    const flagEvaluation$ = isMixpanelConfigured
      ? this.getMixpanelConfiguredFeatureFlag(flag, !!defaultValue)
      : this.getFeatureFlag(flag, defaultValue);
    const setAnonymousUuid$ = defer(() => from(this.launchDarklyService.setAnonymousUserUUID()));
    return iif(() => this.shouldSetAnonymousUUID(), setAnonymousUuid$, observableOf(null)).pipe(
      switchMap(() => flagEvaluation$),
    );
  }

  getMixpanelConfiguredFeatureFlagForAnonymousUser(flag: string, defaultValue?: boolean): Observable<boolean> {
    return this.getFeatureFlagForAnonymousUser(flag, defaultValue, true);
  }

  private shouldSetAnonymousUUID(): boolean {
    return this.launchDarklyService.isAnonymousUnkeyedUser();
  }
}
