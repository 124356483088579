<div class="col-12 row p-0 m-0">
  <form
    class="col-12 col-xl-6 col-lg-8 offset-lg-2 offset-xl-0 mt-xl-5 mt-3 pb-3"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
    <div class="col-xl-8 offset-xl-3 col-12 mt-3 mt-xl-3 p-0 px-xl-3">
      <h1> Welcome to <span class="text-nowrap">One Medical.</span> </h1>
      <div class="g-0 mb-4">
        <p class="col-12">
          Activate your sponsored membership to get exceptional primary care, same or next day appointments, and 24/7
          virtual care with our app.
        </p>
      </div>
      <div *ngIf="hasGenericError" class="col-12 d-flex flex-row mb-3 p-0 generic-error-message">
        <div class="align-top p-3">
          <img width="18" src="/assets/images/icons/icon-warning.svg" />
        </div>
        <div class="pt-3">
          <h6 class="m-0">Something went wrong on our end</h6>
          <p>Please <a href (click)="reload($event)">refresh the page</a> and try again.</p>
        </div>
      </div>
      <h5 class="mb-4">Let's check your access</h5>
      <div class="col-12 p-0">
        <div class="row">
          <div class="col-12 col-lg-6 mb-4">
            <om-omgui-input
              inputId="first-name"
              [formControl]="form.controls.firstName"
              placeholder="Enter first name"
              inputLabel="Legal First Name"
              [errorMessage]="
                firstName.value === '' && firstName.touched && firstName.dirty ? 'First name is required' : ''
              "
              data-cy="first-name-input"
            ></om-omgui-input>
          </div>
          <div class="col-12 col-lg-6 mb-4">
            <om-omgui-input
              inputId="last-name"
              [formControl]="form.controls.lastName"
              placeholder="Enter last name"
              inputLabel="Legal Last Name"
              [errorMessage]="
                lastName.value === '' && lastName.touched && lastName.dirty ? 'Last name is required' : ''
              "
            ></om-omgui-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <om-omgui-date-input
              #dob
              inputId="dob"
              [formControl]="form.controls.dob"
              inputLabel="Birthday"
              placeholder="MM/DD/YYYY"
            ></om-omgui-date-input>
          </div>
        </div>
      </div>
      <div class="mt-4 pt-3 mb-4">
        <om-submit-button class="col-12" label="Next" [submitting]="submitting"></om-submit-button>
      </div>
      <div class="col-12 text-center">
        <small>
          Already have an account?
          <a data-cy="update-membership" href="" (click)="logIn($event, false)">Update your membership</a>
        </small>
      </div>
    </div>
  </form>
  <om-landing-page-value-prop [numberOfOffices]="numberOfOffices"></om-landing-page-value-prop>
</div>
<ng-template #alreadyRegistered>
  <om-modal-template
    heading="An account already exists"
    subheader="The information you provided is already associated with an account. Log in to convert your membership or contact our membership advisors for help."
    primaryCallToActionLabel="Log In"
    primaryClasses="modal-footer pt-0 px-md-5 pb-4 border-0 justify-content-center"
    (close)="closeModal()"
    (primaryCallToAction)="logIn($event, true)"
  >
    <om-svg-exclamation></om-svg-exclamation>
    <small custom class="d-block text-center pb-4">
      Lost access to your account? <a href="mailto:hello@onemedical.com">hello@onemedical.com</a>
    </small>
  </om-modal-template>
</ng-template>
