import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { merge, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { NativeAppService } from '@app/shared/native-app.service';

/**
 * This service's main method decides whether the navbar should be hidden or not. It allows components that don't want
 * to show the navbar to communicate with the components that can control the navbar.
 *
 * It checks two conditions, where if either or both conditions are true, the navbar should be hidden.
 * The conditions are:
 *   1. The path's leaf route's `data.hideNavbar` value.
 *   2. The app being loaded inside a mobile WebView.
 *
 * Here's an example of how to configure the route's data to hide the navbar
 * @example
 * ```
 *   const routes: Routes = [
 *   ...
 *   {
 *     path: 'some-path',
 *     component: someComponent,
 *     data: {
 *       hideNavbar: true,
 *     },
 *   },
 *   ...
 * ];
 * ```
 */
@Injectable({
  providedIn: 'root',
})
export class NavbarService {
  constructor(private route: ActivatedRoute, private router: Router, private nativeAppService: NativeAppService) {}

  shouldHideNavbar$(): Observable<boolean> {
    const initialRouteData$ = this.getLeafRoute().data;
    const navigationEndRouteData$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      switchMap(() => this.getLeafRoute().data),
    );

    return merge(initialRouteData$, navigationEndRouteData$).pipe(
      map(routeData => routeData.hideNavbar || this.nativeAppService.isWebView(this.route)),
    );
  }

  private getLeafRoute(): ActivatedRoute {
    let currentRoute = this.route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    return currentRoute;
  }
}
