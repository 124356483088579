<form class="col-12 row g-0 px-0 mt-3 mt-md-5 mt-xl-0" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="col-12 col-md-8 offset-md-2 col-xl-6 offset-xl-0 mt-xl-10">
    <div class="col-12 col-xl-8 offset-xl-2 mt-xl-10">
      <ng-container *ngIf="hasVirtualServiceAreas$ | async; else enterpriseNonVirtual">
        <h1>Where are you located?</h1>
        <p> We offer in-person care in the cities below as well as 24/7 virtual care from anywhere. </p>
        <p> Not in one of the areas listed? Select “Virtual." </p>
      </ng-container>

      <ng-template #enterpriseNonVirtual>
        <h1>Where will you primarily receive care?</h1>
        <p>
          You can visit any of our {{ numberOfOffices }}+ offices in convenient locations across the US, whether you’re
          at home or traveling. Select the region you plan to visit most.
        </p>
      </ng-template>

      <om-label-wrapper
        class="mb-5"
        errorMessage="You must select a service area."
        label="Select a Location"
        [hasError]="showError()"
      >
        <select
          class="form-control mb-1"
          *ngIf="serviceAreaService.serviceAreas$ | async as serviceAreas"
          formControlName="serviceArea"
        >
          <option><!-- blank option chosen by default --></option>
          <option *ngFor="let serviceArea of serviceAreas" [ngValue]="serviceArea.code">{{ serviceArea.name }} </option>
        </select>
      </om-label-wrapper>
      <om-submit-button class="col-12 d-block px-0 mt-5" label="Next"></om-submit-button>
    </div>
  </div>
  <div class="d-none col-xl-6 d-xl-block" *ngIf="serviceAreaService.nonVirtualServiceAreas$ | async as serviceAreas">
    <om-service-area-map
      [serviceAreas]="serviceAreas"
      [selectedServiceArea]="selectedServiceArea"
      (markerClick)="onMarkerClick($event)"
    ></om-service-area-map>
  </div>
</form>

<ng-template #virtualServiceAreaModal let-dismiss="closeModal()">
  <om-modal-template
    heading="It looks like you are in our virtual care area"
    subheader="We don’t currently have any offices in your area. But the good news is you can get 24/7 care over video chat and messages with providers in our app — whenever and wherever you need it."
    primaryCallToActionLabel="Continue as Virtual Member"
    (primaryCallToAction)="continueRegistrationFlow()"
    (close)="closeModal()"
  >
    <om-svg-phone-in-blue-circle></om-svg-phone-in-blue-circle>
  </om-modal-template>
</ng-template>
