import { Component, ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: 'ng-template[omgui-feature-card-primary-action]',
})
export class FeatureCardPrimaryActionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({
  selector: 'ng-template[omgui-feature-card-secondary-action]',
})
export class FeatureCardSecondaryActionDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Component({
  selector: 'omgui-feature-card-content',
  templateUrl: './feature-card-content.component.html',
  styleUrls: ['./feature-card-content.component.scss', '../../../omgui/_omgui-button.scss'],
})
export class FeatureCardContentComponent {
  @ContentChild(FeatureCardPrimaryActionDirective) primaryAction: FeatureCardPrimaryActionDirective;
  @ContentChild(FeatureCardSecondaryActionDirective) secondaryAction: FeatureCardSecondaryActionDirective;
  @Input() title: string;
  @Input() description: string;
  @Input() imageUrl: string;
  @Input() imageAltText: string;
}
