<ngb-alert
  [dismissible]="dismissible"
  [type]="bannerType"
  [animation]="true"
  (closed)="dismissed.emit()"
  class="text-center py-3"
  [ngClass]="{ 'mb-0': excludeBottomMargin }"
>
  <ng-content></ng-content>
</ngb-alert>
