<omgui-summary-row
  [pageState]="pageState"
  *ngFor="let contact of data.programImmediateCare.immediateCareContacts"
  data-cy="immediate-care-row"
>
  <ng-template omgui-summary-row-icon>
    <om-phone class="icon-svg primary-stroke"></om-phone>
  </ng-template>
  <ng-template omgui-summary-row-primary-text>
    {{ contact.title
    }}<om-tooltip class="ms-1" [text]="contact.tooltipText" placement="top" label="Warmline tooltip"></om-tooltip>
  </ng-template>
  <ng-template omgui-summary-row-secondary-text>
    Call
    <a
      data-cy="contact-link"
      aria-label="Call the immediate care phone number"
      [omTrackLink]="trackImmediateCareClicked()"
      [href]="contact.protocol + ':' + contact.contactValue"
      >{{ contact.contactDisplay }}</a
    >
  </ng-template>
</omgui-summary-row>
