import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { of as observableOf } from 'rxjs';

import { WindowService } from '@app/utils/window.service';

import { AuthServiceImplementation } from './auth.service';
import { LinksService } from './links.service';
import { StorageService } from './storage.service';

// **** This Injectable should only be accessed through the AuthService ****

@Injectable({
  providedIn: 'root',
})
/**
 * This Class is specifically for handling the onelife implementation of authentication
 * We are moving away from this towards auth0; separating the implementation from the
 * interface will allow use to seemlessly switch to a new implementation
 */
export class OnelifeAuthService implements AuthServiceImplementation {
  static TOKEN_KEY = 'api_token';

  private get _token() {
    return this.storageService.getItem(OnelifeAuthService.TOKEN_KEY);
  }

  constructor(
    private linksService: LinksService,
    private router: Router,
    private storageService: StorageService,
    private windowService: WindowService,
  ) {
    const token = this.storageService.getItem(OnelifeAuthService.TOKEN_KEY);
    if (token) {
      this.setToken(token);
    }
  }

  init() {
    return observableOf(true);
  }

  getToken() {
    return observableOf(this._token);
  }

  goLogin(returnUrl = '/') {
    this.router.navigate(['login'], { queryParams: { returnUrl } });
  }

  logout(returnURI = '/', loginBeforeRedirect = true): void {
    this.storageService.removeItem(OnelifeAuthService.TOKEN_KEY);
    this.storageService.removeTldItem(OnelifeAuthService.TOKEN_KEY);

    const redirectUri = loginBeforeRedirect
      ? this.linksService.loginWithQueryParams({ returnUrl: returnURI })
      : returnURI;
    this.windowService.redirect(redirectUri);
  }

  setToken(token: string) {
    this.storageService.removeItem(OnelifeAuthService.TOKEN_KEY);
    this.storageService.removeTldItem(OnelifeAuthService.TOKEN_KEY);
    this.storageService.setTldItem(OnelifeAuthService.TOKEN_KEY, token);
  }
}
