import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Query, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
  ProgramEnrollments as ProgramEnrollmentsResult,
  ProgramEnrollments_patient_programEnrollments_nodes as ProgramEnrollment,
} from '@app/shared/__generated__/ProgramEnrollments';

@Injectable({
  providedIn: 'root',
})
export class ProgramEnrollmentGraphQL extends Query<ProgramEnrollmentsResult> {
  document = gql`
    query ProgramEnrollments {
      patient {
        id
        programEnrollments {
          nodes {
            id
            program {
              displayName
              description
            }
            programPhase {
              name
            }
            careTeam {
              id
              careGivers {
                role
                provider {
                  id
                  displayName
                  profileImageUrl
                  bio
                }
              }
            }
          }
        }
      }
    }
  `;

  enrollmentForProgram$(programName: string): Observable<ProgramEnrollment> {
    return this.fetch().pipe(
      take(1),
      map((result: ApolloQueryResult<ProgramEnrollmentsResult>) =>
        result.data.patient.programEnrollments.nodes.find(
          (enrollment: ProgramEnrollment) => enrollment.program.displayName.toLowerCase() === programName,
        ),
      ),
    );
  }
}
