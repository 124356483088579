import { Component } from '@angular/core';

import { ProgramHubAnalyticsService } from '../program-hub/program-hub-analytics.service';
import { APPOINTMENTS_MODULE } from '../program-types';

@Component({
  selector: 'om-program-appointments',
  templateUrl: './program-appointments.component.html',
  styleUrls: ['./program-appointments.component.scss'],
})
export class ProgramAppointmentsComponent {
  constructor(private programHubAnalyticsService: ProgramHubAnalyticsService) {}

  trackAppointmentsLinkClicked() {
    this.programHubAnalyticsService.trackProgramModuleClicked({ module: APPOINTMENTS_MODULE });
  }
}
