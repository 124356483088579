<div *ngFor="let careGiver of careGivers; let isLastItem = last" data-cy="care-team-row">
  <div class="care-team-member d-flex align-items-center pb-2" [class.border-bottom]="!isLastItem">
    <omgui-profile-bubble class="py-3 pe-3" [profile]="careGiver.provider"></omgui-profile-bubble>
    <div class="provider-detail">
      <p class="card-title align-self-baseline m-0" data-cy="care-team-member-name">{{
        careGiver.provider.displayName
      }}</p>

      <p class="card-text text-muted specialty-text" *ngIf="careGiver.tooltipText" data-cy="care-team-member-role"
        >{{ careGiver.role }}
        <om-tooltip
          class="ms-1"
          [text]="careGiver.tooltipText"
          placement="top"
          label="Care Team tooltip"
          (tooltipShown)="trackCareTeamRoleHover(careGiver.role)"
        ></om-tooltip>
      </p>
    </div>
  </div>
</div>

<a
  class="btn btn-outline-primary w-100 mt-1"
  routerLink="/messages"
  [omTrackLink]="trackMessageClicked"
  data-cy="send-message-button"
  >Send a message</a
>
