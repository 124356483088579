import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';

import { AlreadyRegisteredError } from '@app/registration/enterprise/whitelisted-employee-errors';
import { OmguiDateInputComponent } from '@omgui/omgui-date-input/omgui-date-input.component';

import { RegistrationStepDirective } from '../registration-step.directive';

@Component({
  selector: 'om-personal-details-step',
  styleUrls: ['./personal-details-step.component.scss'],
  templateUrl: './personal-details-step.component.html',
})
export class PersonalDetailsStepComponent extends RegistrationStepDirective {
  @Input() numberOfOffices: number;
  @Input() errorMessage: string;
  @Input() hasGenericError = false;
  @Output() alreadyRegisteredModalViewed: EventEmitter<any> = new EventEmitter<any>();
  @Output() alreadyRegisteredModalClosed: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('alreadyRegistered', { static: true }) alreadyRegistered: TemplateRef<any>;
  @ViewChild('dob', { static: true }) dob: OmguiDateInputComponent;

  alreadyRegisteredModal: NgbModalRef;

  constructor(private modalService: NgbModal) {
    super();
  }

  logIn(event: Event, fromModal: boolean) {
    if (event) {
      event.preventDefault();
    }
    this.closeModal(false);
    this.login.emit(fromModal);
  }

  onError(err: Error) {
    this.submitting = false;
    if ((err as AlreadyRegisteredError).alreadyRegistered) {
      this.alreadyRegisteredModal = this.modalService.open(this.alreadyRegistered);
      this.alreadyRegisteredModalViewed.emit();
      this.alreadyRegisteredModal.result.then(null, result => {
        if (result === 0 || result === true) {
          this.alreadyRegisteredModalClosed.emit();
        }
      });
    }
  }

  onSubmit() {
    this.validateAndMarkAllAsTouchedAndDirty();
    this.submitting = true;
    this.submit.emit(this.form.value);
  }

  closeModal(track = true) {
    if (this.alreadyRegisteredModal) {
      this.alreadyRegisteredModal.dismiss(track);
    }
  }

  reload(event: Event) {
    event.preventDefault();
    window.location.reload();
  }

  get firstName(): AbstractControl {
    return this.form.controls.firstName;
  }

  get lastName(): AbstractControl {
    return this.form.controls.lastName;
  }

  private validateAndMarkAllAsTouchedAndDirty() {
    this.dob.validateDate({ value: this.form.value.dob });
    this.form.markAllAsTouched();
    this.form.controls.firstName.markAsDirty();
    this.form.controls.lastName.markAsDirty();
    this.form.controls.dob.markAsDirty();
  }
}
