<div *ngIf="visible" [@fadeInOut]>
  <div
    class="px-3 py-2 inline-icon-with-text container"
    aria-live="assertive"
    aria-atomic="true"
    role="alert"
    data-cy="toast"
  >
    {{ message }}
    <span class="ms-2" (click)="close()" role="button">
      <omgui-close-x stroke="white" fill="currentColor" width="24" height="24"></omgui-close-x>
    </span>
  </div>
</div>
