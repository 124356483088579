import { AbstractControl } from '@angular/forms';

// Note: we don't want to use ngrx-forms built-in "email" validator because it is inconsistent with what we validate
// on the backend, because it explicitly allows emails without top-level domains - https://github.com/MrWolfZ/ngrx-forms/blob/0cc6ca32deeabcfeabb4cb63427e435eb534301f/validation/src/email.spec.ts#L23-L25
// In rails, we explicitly require top-level domain, although our rails validation regex is a lot simpler.
// This regex is taken from ngrx-forms code, but amended to explicitly require top-level domain, too.
export const emailRegex = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])\.([A-Za-z0-9-]{0,61}[A-Za-z0-9])([A-Za-z0-9](\.[A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;

export function emailValid(email: string): boolean {
  return emailRegex.test(email);
}

export function emailValidator(control: AbstractControl) {
  return emailValid(control.value) ? null : { invalidEmail: { value: control.value } };
}
