import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ProgramHubAnalyticsService } from '../program-hub/program-hub-analytics.service';
import { FAQ_MODULE, ProgramFaqs } from '../program-types';

@Component({
  selector: 'om-program-faq',
  templateUrl: './program-faq.component.html',
  styleUrls: ['./program-faq.component.scss'],
})
export class ProgramFaqComponent implements OnInit, OnDestroy {
  @Input() data: ProgramFaqs;

  constructor(private programHubAnalyticsService: ProgramHubAnalyticsService) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  trackFaqClicked(index: number): void {
    this.programHubAnalyticsService.trackProgramModuleClicked({ module: FAQ_MODULE + `${index + 1}` });
  }
}
