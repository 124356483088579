import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import noop from 'lodash-es/noop';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';

import { ApiService } from '@app/core/api.service';
import { PusherService } from '@app/shared';

interface ApiV2FitbitStatus {
  connected: boolean;
  auth_url: string;
}

interface SaveAuthCodeResponse {
  success: boolean;
}

class FitbitStatus {
  connected: boolean;
  authUrl: string;

  static fromApiV2(response: ApiV2FitbitStatus): FitbitStatus {
    const status = new FitbitStatus();
    status.connected = response.connected;
    status.authUrl = response.auth_url;

    return status;
  }
}

@Injectable()
export class FitbitService {
  private _status$ = new BehaviorSubject<FitbitStatus>(null);
  readonly status$ = this._status$.asObservable().pipe(filter(data => data !== null));

  private CREATE_ENDPOINT = '/api/v2/patient/connected_accounts/fitbit_accounts';
  private SHOW_ENDPOINT = '/api/v2/patient/connected_accounts/fitbit_accounts';

  constructor(private apiService: ApiService, private pusherService: PusherService, private router: Router) {}

  getFitbitStatus(force = false): Observable<any> {
    if (!(force || this._status$.getValue() === null)) {
      return this._status$.pipe(take(1));
    }

    const request = this.apiService.get(this.SHOW_ENDPOINT).pipe(
      map((response: ApiV2FitbitStatus) => FitbitStatus.fromApiV2(response)),
      tap((status: FitbitStatus) => this._status$.next(status)),
    );

    request.subscribe({ error: noop });

    return request;
  }

  saveAuthCode(authCode: string) {
    return this.apiService.post(this.CREATE_ENDPOINT, { code: authCode }).subscribe({
      next: ({ success }: SaveAuthCodeResponse) => {
        const result = success ? 'success' : 'error';
        this.navigateToConnectedAccountsPage(result);
      },
      error: _error => {
        this.navigateToConnectedAccountsPage('error');
      },
    });
  }

  navigateToConnectedAccountsPage(result: string) {
    const queryParams = {
      account_authorization_result: result,
    };
    this.router.navigate(['/account/connected-accounts'], { queryParams: queryParams });
  }
}
