<div class="omgui-feature-card-content row g-0 flex-column flex-sm-row px-sm-4 pb-sm-4">
  <div class="col col-md-6">
    <img class="card-img" [src]="imageUrl" [alt]="imageAltText" data-cy="omgui-feature-card-image" />
  </div>
  <div class="col col-sm-6 p-3 ps-sm-4 py-sm-0 pe-sm-0">
    <h3 class="card-title h5" data-cy="omgui-feature-card-title">{{ title }}</h3>
    <p class="card-text body-small" data-cy="omgui-feature-card-description">{{ description }}</p>
    <div *ngIf="primaryAction || secondaryAction">
      <ng-container [ngTemplateOutlet]="primaryAction?.templateRef"></ng-container>
      <ng-container [ngTemplateOutlet]="secondaryAction?.templateRef"></ng-container>
    </div>
  </div>
</div>
