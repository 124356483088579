import { Component, Input } from '@angular/core';

@Component({
  selector: 'om-svg-info',
  templateUrl: './svg-info.component.html',
})
export class SvgInfoComponent {
  @Input() width = 25;
  @Input() height = 25;
  @Input() stroke = 'black';
}
