import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { AttemptedPathService } from '@app/core/attempted-path.service';

import { AnalyticsService } from './analytics.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    private attemptedPathService: AttemptedPathService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const authenticated$ = this.authService.initialized$.pipe(
      map(() => {
        if (this.authService.isTokenBlank()) {
          this.handleUnauthenticated(state.url);
          return false;
        }
        return true;
      }),
    );

    return authenticated$.pipe(
      concatMap(authenticated => {
        if (!authenticated) {
          return observableOf(false);
        }
        return observableOf(true);
      }),
      catchError(() => this.handleUnauthenticated(state.url)),
    );
  }

  private handleUnauthenticated(url: string) {
    this.analyticsService.trackDirectLinksToLoggedInResources(url);
    this.attemptedPathService.setAttemptedPath(url);
    this.authService.goLogin(url);
    return observableOf(false);
  }
}
