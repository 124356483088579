import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable, concat } from 'rxjs';
import { every, map, take } from 'rxjs/operators';

import { Membership } from '@app/core/membership';
import { MembershipGraphQL } from '@app/core/membership-graphql.service';
import { PediatricMembershipTypeService } from '@app/registration/pediatric/pediatric-membership-type.service';

import { MembershipResult } from './__generated__/MembershipResult';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable()
export class EnterpriseCorePlusPlanGuardService implements CanActivate {
  membership: Membership;

  constructor(
    private router: Router,
    private membershipGraphQL: MembershipGraphQL,
    private pediatricMembershipType: PediatricMembershipTypeService,
    private authService: AuthService,
    private userService: UserService,
  ) {}

  canActivate(): boolean | Observable<boolean> {
    const authInit$ = this.authService.initialized$.pipe(take(1));
    const membership$ = this.membershipGraphQL.fetch().pipe(
      map((result: ApolloQueryResult<MembershipResult>) => {
        if (this.authService.isLoggedIn()) {
          this.userService.getUser();
          let membership;
          if (result.data.membership) {
            membership = result.data.membership;
          }
          if (membership && this.pediatricMembershipType.isCorePlusEnterprise(membership)) {
            return true;
          } else {
            this.router.navigate(['/'], { replaceUrl: true });
            return false;
          }
        } else {
          this.router.navigate(['/'], { replaceUrl: true });
          return false;
        }
      }),
      take(1),
    );

    return concat(authInit$, membership$).pipe(
      every(x => x === true),
      map(x => {
        if (x === false) {
          this.router.navigate(['/'], { replaceUrl: true });
        }
        return x;
      }),
    );
  }
}
