import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { LinksService } from '@app/core/links.service';
import { BannerType } from '@omgui/omgui-banner/omgui-banner.component';

import { SendForgotPasswordEmailGraphQL } from './send-forgot-password-email-graphql.service';

@Component({
  selector: 'om-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPassword implements OnInit {
  readonly genericErrorMessage = 'There was a problem. Please try again later.';
  readonly BannerType = BannerType;

  forgotPasswordForm: UntypedFormGroup;
  isInvalidFormSubmitted = false;
  processing = false;
  showErrorMsg = false;
  showSuccessMsg = false;

  constructor(readonly links: LinksService, private sendForgotPasswordEmail: SendForgotPasswordEmailGraphQL) {}

  ngOnInit() {
    this.forgotPasswordForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.email, Validators.required]),
      preferredEmail: new UntypedFormControl(''),
    });
  }

  get emailControl() {
    return this.forgotPasswordForm.get('email') as UntypedFormControl;
  }

  get email() {
    return this.forgotPasswordForm.controls['email'].value;
  }

  get preferredEmail() {
    return Boolean(this.forgotPasswordForm.controls['preferredEmail'].value);
  }

  private resetFormAndState() {
    this.forgotPasswordForm.reset();
    this.resetFormState();
  }

  private resetFormState() {
    this.isInvalidFormSubmitted = false;
    this.processing = false;
  }

  onSubmit() {
    this.showSuccessMsg = false;
    this.showErrorMsg = false;

    if (this.forgotPasswordForm.valid) {
      this.processing = true;
      this.sendForgotPasswordEmail.mutate({ email: this.email, usePreferredEmail: this.preferredEmail }).subscribe({
        next: ({ data: { sendForgotPasswordEmail } }) => {
          if (sendForgotPasswordEmail.success) {
            this.showSuccessMsg = true;
            this.resetFormAndState();
          } else {
            this.showErrorMsg = true;
            this.resetFormState();
          }
        },
        error: () => {
          this.showErrorMsg = true;
          this.resetFormState();
        },
      });
    } else {
      this.isInvalidFormSubmitted = true;
    }
  }
}
