import { Validators } from '@angular/forms';
import camelCase from 'lodash-es/camelCase';
import each from 'lodash-es/each';
import find from 'lodash-es/find';
import flatten from 'lodash-es/flatten';

export interface BasicAddress {
  address1: string;
  address2: string;
  city: string;
  state: string;
  stateId?: number;
  zip: string;
}

export class Address implements BasicAddress {
  id: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  stateId: number;
  zip: string;
  latitude: number;
  longitude: number;
  preferred: boolean;

  get fullAddress(): string {
    return [this.address1, this.address2].filter(n => !!n).join(', ');
  }

  constructor(attrs: Record<string, any> = {}) {
    this.setAttributes(attrs);
  }

  static forApiV2(address: Address): Record<string, any> {
    const addressAsJson: Record<string, any> = {};

    each(['id', 'address1', 'address2', 'city', 'zip'], attrName => {
      const addressAsRecord = address as Record<string, any>;
      if (addressAsRecord[attrName]) {
        addressAsJson[attrName] = addressAsRecord[attrName];
      }
    });

    addressAsJson.state_id = address.stateId;
    addressAsJson.state_code = address.state;
    addressAsJson.is_preferred = address.preferred;

    return addressAsJson;
  }

  static get emptyAddress(): BasicAddress {
    return <BasicAddress>{
      address1: '',
      address2: '',
      city: '',
      stateId: null,
      state: '',
      zip: '',
    };
  }

  static buildBasicAddressControlsConfig(initialAddress: BasicAddress = Address.emptyAddress) {
    return {
      address1: [initialAddress.address1, [Validators.required]],
      address2: initialAddress.address2,
      city: [initialAddress.city, Validators.required],
      state: [initialAddress.state, Validators.required],
      stateId: [initialAddress.stateId || ''],
      zip: [initialAddress.zip, [Validators.required, Validators.pattern(/^\d+$/)]],
    };
  }

  static parseAddress(selectedLocation: google.maps.places.PlaceResult): BasicAddress {
    return Object.assign(
      Address.emptyAddress,
      parseAddress1(selectedLocation),
      parseCity(selectedLocation),
      parseState(selectedLocation),
      parseZip(selectedLocation),
    );
  }

  static fromAutocompletePrediction(result: google.maps.places.AutocompletePrediction): BasicAddress {
    return Object.assign(Address.emptyAddress, {
      address1: result.structured_formatting.main_text,
      city: result.structured_formatting.secondary_text.split(',')[0].trim(),
      state: result.structured_formatting.secondary_text.split(',')[1].trim(),
    });
  }

  static hasAllStreetAddressComponents(address: BasicAddress) {
    return address.address1 !== '' && address.city !== '' && address.state !== '' && address.zip !== '';
  }

  private setAttributes(attrs: Record<string, any>): void {
    each(['id', 'address1', 'address2', 'city', 'state', 'state_id', 'zip', 'latitude', 'longitude'], attrName => {
      if (attrs[attrName]) {
        (<Record<string, any>>this)[camelCase(attrName)] = attrs[attrName];
      }
    });
  }
}

function parseAddress1(selectedLocation: google.maps.places.PlaceResult) {
  const streetNumber = findComponentByType(selectedLocation, 'street_number');
  const street = findComponentByType(selectedLocation, 'route');
  if (streetNumber || street) {
    return { address1: selectedLocation.name };
  }
}

function parseCity(selectedLocation: google.maps.places.PlaceResult) {
  let cityComponent = findComponentByType(selectedLocation, 'locality');
  if (!cityComponent) {
    cityComponent = <google.maps.GeocoderAddressComponent>(
      find(selectedLocation.address_components, comp => comp.types[0].match(/locality/))
    );
  }
  if (cityComponent) {
    return { city: cityComponent.long_name };
  }
}

function parseState(selectedLocation: google.maps.places.PlaceResult) {
  const stateComponent = findComponentByType(selectedLocation, 'administrative_area_level_1');
  if (stateComponent) {
    return { state: stateComponent.short_name };
  }
}

function parseZip(selectedLocation: google.maps.places.PlaceResult) {
  const zipComponent = findComponentByType(selectedLocation, 'postal_code');
  if (zipComponent) {
    return { zip: zipComponent.long_name };
  }
}

function findComponentByType(
  selectedLocation: google.maps.places.PlaceResult,
  type: string,
): google.maps.GeocoderAddressComponent {
  return find(selectedLocation.address_components, comp => flatten(comp.types).indexOf(type) > -1);
}
