import { CommonModule } from '@angular/common';
import { Component, Input, NgModule } from '@angular/core';

import { ComponentSize } from '@omgui/component-size';

@Component({
  selector: 'omgui-spinner',
  template: `
    <svg [ngSwitch]="size" [ngClass]="'spinner ' + size" data-cy="spinner">
      <circle *ngSwitchCase="componentSize.extraSmall" cx="21" cy="21" r="19"></circle>
      <circle *ngSwitchCase="componentSize.small" cx="26" cy="26" r="24"></circle>
      <circle *ngSwitchCase="componentSize.mediumSmall" cx="31" cy="31" r="29"></circle>
      <circle *ngSwitchCase="componentSize.medium" cx="51" cy="51" r="49"></circle>
      <circle *ngSwitchCase="componentSize.large" cx="68.5" cy="68.5" r="66.5"></circle>
      <circle *ngSwitchCase="componentSize.extraLarge" cx="93.5" cy="93.5" r="91.5"></circle>
    </svg>
  `,
  styleUrls: ['./omgui-spinner.component.scss'],
})
export class OmguiSpinnerComponent {
  @Input() size = ComponentSize.small;

  readonly componentSize = ComponentSize;
}

@NgModule({
  declarations: [OmguiSpinnerComponent],
  imports: [CommonModule],
  exports: [OmguiSpinnerComponent],
})
export class OmguiSpinnerModule {}
