import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnChanges } from '@angular/core';

import { OmguiProfileBubbleModule, UserType } from '@omgui/omgui-profile-bubble/omgui-profile-bubble.component';

import { ComponentSize } from '../component-size';
import { Profile } from '../omgui-profile-bubble/omgui-profile-bubble.component';

@Component({
  selector: 'omgui-multiple-avatar-display',
  templateUrl: 'omgui-multiple-avatar-display.component.html',
  styleUrls: ['omgui-multiple-avatar-display.component.scss'],
})
export class OmguiMultipleAvatarDisplayComponent implements OnChanges {
  /** Limits the number of avatars that will be displayed. Those over the limit will be shown as a summary bubble. */
  @Input() displayedAvatarsMaximum: number;

  /** Contains the name and image data for the bubbles. */
  @Input() providerProfiles: Profile[];

  /** Size to render the profile bubbles */
  @Input() size: ComponentSize = ComponentSize.small;

  /** @ignore */
  displayedProfiles: Profile[];
  /** @ignore */
  readonly componentSize = ComponentSize;

  /** @ignore */
  ngOnChanges(): void {
    this.makeBubbleInputs();
  }

  /** @ignore */
  private makeBubbleInputs(): void {
    const displayedProfiles = this.providerProfiles.slice(0, this.displayedAvatarsMaximum);

    const hiddenAvatarsCount = this.providerProfiles.length - this.displayedAvatarsMaximum;
    if (hiddenAvatarsCount > 0) {
      displayedProfiles.push({
        preferredName: '+',
        lastName: hiddenAvatarsCount.toString(),
        type: UserType.InternalUser,
      });
    }

    this.displayedProfiles = displayedProfiles;
  }
}

@NgModule({
  declarations: [OmguiMultipleAvatarDisplayComponent],
  imports: [CommonModule, OmguiProfileBubbleModule],
  exports: [OmguiMultipleAvatarDisplayComponent],
})
export class OmguiMultipleAvatarDisplayModule {}
