<ng-template #cardContent>
  <div class="card omgui-card p-3 d-flex flex-column flex-sm-row justify-content-sm-start align-items-center h-100">
    <div *ngIf="icon" class="me-sm-3 mb-1 mb-sm-0">
      <ng-container *ngTemplateOutlet="icon.templateRef"></ng-container>
    </div>

    <h4 class="h5 m-0 text-center text-sm-start"> {{ label }} </h4>
  </div>
</ng-template>

<ng-container *ngIf="link; then linkView; else staticView"></ng-container>

<ng-template #linkView>
  <a
    [routerLink]="link"
    [omTrackLink]="trackLink"
    [queryParams]="queryParams"
    class="text-decoration-none omgui-card-link-wrapper"
  >
    <ng-container *ngTemplateOutlet="cardContent"></ng-container>
  </a>
</ng-template>

<ng-template #staticView>
  <ng-container *ngTemplateOutlet="cardContent"></ng-container>
</ng-template>
